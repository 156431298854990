"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscreteMapping = void 0;
const Mapping_1 = require("./Mapping");
class DiscreteMapping extends Mapping_1.Mapping {
    constructor(scale, values) {
        super(scale);
        this.values = values;
    }
    index(value) {
        return this.values.indexOf(value);
    }
    map(value) {
        return this.scale.map(this.values.indexOf(value) % this.scale.stops.length);
    }
}
exports.DiscreteMapping = DiscreteMapping;
