"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurrentTool = void 0;
const ToolSelection_1 = require("../Overlays/ToolSelection/ToolSelection");
const SET = "ducks/currentTool/SET";
exports.setCurrentTool = currentTool => ({
    type: SET,
    currentTool: currentTool
});
const currentTool = (state = ToolSelection_1.Tool.Default, action) => {
    switch (action.type) {
        case SET:
            return action.currentTool;
        default:
            return state;
    }
};
exports.default = currentTool;
