"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adaptDynamicColorToBgColor = exports.adaptTextColorToBgColor = exports.setText = exports.noRenderer = exports.noop = exports.CANVAS_HEIGHT = void 0;
const d3_color_1 = require("d3-color");
exports.CANVAS_HEIGHT = 4;
/** @internal */
function noop() {
    // no op
}
exports.noop = noop;
exports.noRenderer = {
    template: `<div></div>`,
    update: noop
};
/** @internal */
function setText(node, text) {
    if (text === undefined) {
        return node;
    }
    if (node.textContent !== text) {
        node.textContent = text;
    }
    return node;
}
exports.setText = setText;
// side effect
const adaptColorCache = {};
/**
 * Adapts the text color for a given background color
 * @param {string} bgColor as `#ff0000`
 * @returns {string} returns `black` or `white` for best contrast
 */
function adaptTextColorToBgColor(bgColor) {
    const bak = adaptColorCache[bgColor];
    if (bak) {
        return bak;
    }
    return adaptColorCache[bgColor] = d3_color_1.hsl(bgColor).l > 0.5 ? 'black' : 'white';
}
exports.adaptTextColorToBgColor = adaptTextColorToBgColor;
/**
 *
 * Adapts the text color for a given background color
 * @param {HTMLElement} node the node containing the text
 * @param {string} bgColor as `#ff0000`
 * @param {string} title the title to render
 * @param {number} width for which percentages of the cell this background applies (0..1)
 */
function adaptDynamicColorToBgColor(node, bgColor, title, width) {
    const adapt = adaptTextColorToBgColor(bgColor);
    if ((width <= 0.05 || adapt === 'black') || width > 0.9) { // almost empty or full
        node.style.color = adapt === 'black' || width <= 0.05 ? null : adapt; // null = black
        // node.classList.remove('lu-gradient-text');
        // node.style.backgroundImage = null;
        return;
    }
    node.style.color = null;
    node.innerText = title;
    const span = node.ownerDocument.createElement('span');
    span.classList.add('lu-gradient-text');
    span.style.color = adapt;
    span.innerText = title;
    node.appendChild(span);
}
exports.adaptDynamicColorToBgColor = adaptDynamicColorToBgColor;
