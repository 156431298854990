"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openStoryEditor = void 0;
const OPEN = "ducks/storyEditor/OPEN";
exports.openStoryEditor = (visible) => ({
    type: OPEN,
    visible: visible
});
const initialState = {
    visible: false
};
const storyEditor = (state = initialState, action) => {
    switch (action.type) {
        case OPEN:
            return { visible: action.visible };
        default:
            return state;
    }
};
exports.default = storyEditor;
