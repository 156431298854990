"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDFModifierDialog = void 0;
const React = require("react");
const core_1 = require("@material-ui/core");
function SDFModifierDialog({ openSDFDialog, handleClose }) {
    const [modifiers, setModifiers] = React.useState("");
    function handleModifierChange(event) {
        setModifiers(event.target.value);
    }
    return React.createElement(core_1.Dialog, { maxWidth: 'lg', open: openSDFDialog, onClose: () => handleClose(null) },
        React.createElement(core_1.DialogTitle, null, "Specify Modifiers"),
        React.createElement(core_1.DialogContent, null,
            React.createElement(core_1.DialogContentText, null,
                "Manually specify modifiers separated by semicolons e.g. \"pred;fp;latent\". ",
                React.createElement("br", null),
                "You can also leave this field empty, if the modifiers are included by default. ",
                React.createElement("br", null),
                "The following modifiers are included by default: \"pred\", \"predicted\", \"measured\", \"fingerprint\", \"rep\"."),
            React.createElement(core_1.TextField, { autoFocus: true, margin: "dense", id: "modifiers", label: "Modifiers", value: modifiers, onChange: handleModifierChange, fullWidth: true })),
        React.createElement(core_1.DialogActions, null,
            React.createElement(core_1.Button, { onClick: () => handleClose(null) }, "Cancel"),
            React.createElement(core_1.Button, { onClick: () => handleClose(modifiers) }, "Start")));
}
exports.SDFModifierDialog = SDFModifierDialog;
