"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCategoryOptions = void 0;
const SET = "ducks/categoryOptions/SET";
const categoryOptions = (state = null, action) => {
    switch (action.type) {
        case SET:
            return action.categoryOptions;
        default:
            return state;
    }
};
exports.default = categoryOptions;
exports.setCategoryOptions = categoryOptions => ({
    type: SET,
    categoryOptions: categoryOptions
});
