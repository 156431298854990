"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingIndicatorDialog = exports.LoadingIndicatorView = void 0;
const React = require("react");
const react_promise_tracker_1 = require("react-promise-tracker");
const react_loader_spinner_1 = require("react-loader-spinner");
const core_1 = require("@material-ui/core");
exports.LoadingIndicatorView = props => {
    const { promiseInProgress } = react_promise_tracker_1.usePromiseTracker({ area: props.area });
    return (promiseInProgress &&
        React.createElement("div", { style: {
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            } },
            React.createElement(react_loader_spinner_1.default, { type: "ThreeDots", color: "#2BAD60", height: "100", width: "100" })));
};
exports.LoadingIndicatorDialog = props => {
    const { promiseInProgress } = react_promise_tracker_1.usePromiseTracker({ area: props.area });
    return React.createElement(core_1.Dialog, { maxWidth: 'lg', open: promiseInProgress },
        " ",
        React.createElement(core_1.DialogContent, null,
            React.createElement(exports.LoadingIndicatorView, { area: props.area })),
        React.createElement(core_1.DialogActions, null,
            React.createElement(core_1.Button, { onClick: props.handleClose }, "Cancel")));
};
