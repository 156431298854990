"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VectView = exports.Vect = void 0;
const meshes_1 = require("../../WebGLView/meshes");
/**
 * Main data class for points
 */
class Vect {
    constructor(dict) {
        // Copy dictionary values to this object
        Object.keys(dict).forEach(key => {
            this[key] = dict[key];
        });
        this.__meta__ = {};
        this.setMeta('view', new VectView());
    }
    /**
     * Sets some meta data for a key
     */
    setMeta(key, value) {
        this.__meta__[key] = value;
    }
    /**
     * Gets some meta data for a key
     */
    getMeta(key) {
        return this.__meta__[key];
    }
    /**
     * Getter for view details
     */
    get view() {
        return this.getMeta('view');
    }
    pureValues() {
        var keys = this.pureHeader();
        return keys.map(key => this[key]);
    }
    pureHeader() {
        return Object.keys(this).filter(value => value != '__meta__');
    }
}
exports.Vect = Vect;
/**
 * View information for a vector, this contains all attributes that are not data related, for
 * example the color or the index to the mesh vertex
 */
class VectView {
    constructor() {
        /**
         * Index to the vertice from three
         */
        this.meshIndex = -1;
        /**
         * Is this vector selected?
         */
        this.selected = false;
        /**
         * The segment reference this vector belongs to.
         */
        this.segment = null;
        /**
         * Index of sequence from 0 to n, this is needed because the key for the line might be sortable, but not numeric
         */
        this.sequenceIndex = -1;
        /**
         * Set color for this vertice, if null the color of the line is taken
         */
        this.intrinsicColor = null;
        /**
         * Is this vector visible?
         */
        this.visible = true;
        /**
         * Currently displayed shape of the vector.
         */
        this.shapeType = meshes_1.Shapes.Circle;
        /**
         * Base size scaling for this point
         */
        this.baseSize = 16;
        this.highlighted = false;
        this.duplicateOf = null;
        // Brightness value of this sample.
        this.brightness = 1.0;
        // is this sample filtered out in lineup
        this.lineUpFiltered = false;
    }
}
exports.VectView = VectView;
