"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatasetDrop = void 0;
const core_1 = require("@material-ui/core");
const React = require("react");
const CSVLoader_1 = require("../../Utility/Loaders/CSVLoader");
const JSONLoader_1 = require("../../Utility/Loaders/JSONLoader");
const SDFLoader_1 = require("../../Utility/Loaders/SDFLoader");
const DragAndDrop_1 = require("./DragAndDrop");
const SDFModifierDialog_1 = require("./SDFModifierDialog");
exports.DatasetDrop = ({ onChange, cancellablePromise, abort_controller }) => {
    const [entry, setEntry] = React.useState(null);
    const [openSDFDialog, setOpen] = React.useState(false);
    function onModifierDialogClose(modifiers) {
        setOpen(false);
        if (modifiers !== null) {
            abort_controller = new AbortController();
            new SDFLoader_1.SDFLoader().resolveContent(entry, onChange, cancellablePromise, modifiers, abort_controller);
        }
    }
    return React.createElement(core_1.Grid, { container: true, item: true, alignItems: "stretch", justify: "center", direction: "column", style: { padding: '16px' } },
        React.createElement(DragAndDrop_1.default, { accept: "image/*", handleDrop: (files) => {
                if (files == null || files.length <= 0) {
                    return;
                }
                var file = files[0];
                var fileName = file.name;
                if (fileName.endsWith('sdf')) {
                    setEntry(file);
                    setOpen(true);
                }
                else {
                    var reader = new FileReader();
                    reader.onload = (event) => {
                        var content = event.target.result;
                        if (fileName.endsWith('json')) {
                            new JSONLoader_1.JSONLoader().resolveContent(content, onChange);
                        }
                        else {
                            new CSVLoader_1.CSVLoader().resolveContent(content, onChange);
                        }
                    };
                    reader.readAsText(file);
                }
            } },
            React.createElement("div", { style: { height: 200 } })),
        React.createElement(SDFModifierDialog_1.SDFModifierDialog, { openSDFDialog: openSDFDialog, handleClose: onModifierDialogClose }));
};
