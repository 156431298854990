"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContinuousMapping = void 0;
const Mapping_1 = require("./Mapping");
class ContinuousMapping extends Mapping_1.Mapping {
    constructor(scale, range) {
        super(scale);
        this.range = range;
    }
    map(value) {
        if (this.range.max == this.range.min) {
            return this.scale.map(0);
        }
        var normalized = (value - this.range.min) / (this.range.max - this.range.min);
        return this.scale.map(normalized);
    }
}
exports.ContinuousMapping = ContinuousMapping;
