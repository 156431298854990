"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lineupjs_1 = require("lineupjs");
const utils_1 = require("./utils");
// https://github.com/lineupjs/lineupjs/blob/master/src/renderer/BarCellRenderer.ts
class BarCellRenderer {
    /**
     * flag to always render the value
     * @type {boolean}
     */
    constructor(renderValue = false) {
        this.renderValue = renderValue;
        this.title = 'Bar';
    }
    canRender(col, mode) {
        return mode === lineupjs_1.ERenderMode.CELL && lineupjs_1.isNumberColumn(col) && !lineupjs_1.isNumbersColumn(col);
    }
    create(col, context, imposer) {
        const width = context.colWidth(col);
        return {
            template: `<div title="">
          <div class="lu-bar-label" style='background-color: ${lineupjs_1.DEFAULT_COLOR}'>
            <span ${this.renderValue ? '' : `class="lu-hover-only"`}></span>
          </div>
        </div>`,
            update: (n, d) => {
                const value = col.getNumber(d);
                const missing = lineupjs_1.renderMissingDOM(n, col, d);
                const w = isNaN(value) ? 0 : Math.round(value * 10000) / 100;
                const title = col.getLabel(d);
                n.title = title;
                const bar = n.firstElementChild;
                bar.style.width = missing ? '100%' : `${w}%`;
                const color = lineupjs_1.colorOf(col, d, imposer, value);
                bar.style.backgroundColor = missing ? null : color;
                utils_1.setText(bar.firstElementChild, title);
                const item = bar.firstElementChild;
                utils_1.setText(item, title);
                item.style.color = 'black';
                // adaptDynamicColorToBgColor(item, color || DEFAULT_COLOR, title, w / 100);
            },
            render: (ctx, d) => {
                if (lineupjs_1.renderMissingCanvas(ctx, col, d, width)) {
                    return;
                }
                const value = col.getNumber(d);
                ctx.fillStyle = lineupjs_1.colorOf(col, d, imposer, value) || lineupjs_1.DEFAULT_COLOR;
                const w = width * value;
                ctx.fillRect(0, 0, isNaN(w) ? 0 : w, utils_1.CANVAS_HEIGHT);
            }
        };
    }
    createGroup() {
        return utils_1.noRenderer;
    }
    createSummary() {
        return utils_1.noRenderer;
    }
}
exports.default = BarCellRenderer;
