"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDatasetVectAction = exports.setDatasetAction = void 0;
const SET = "ducks/database/SET";
const SET_VECT = "ducks/database/SET_VECT";
function setDatasetAction(dataset) {
    return {
        type: SET,
        dataset: dataset
    };
}
exports.setDatasetAction = setDatasetAction;
exports.setDatasetVectAction = input => ({
    type: SET_VECT,
    input: input
});
const initialState = null;
function dataset(state = initialState, action) {
    switch (action.type) {
        case SET:
            return action.dataset;
        // case SET_VECT:
        //     console.log(action.input)
        //     const dataset = {...state, vectors: action.input};
        //     return dataset;
        default:
            return state;
    }
}
exports.default = dataset;
