"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericChanges = void 0;
const DatasetType_1 = require("../../Utility/Data/DatasetType");
const react_redux_1 = require("react-redux");
const RubikChanges_1 = require("../RubikChanges/RubikChanges");
const React = require("react");
const ChessChanges_1 = require("../ChessChanges/ChessChanges");
const CoralChanges_1 = require("../CoralChanges/CoralChanges");
const ChemDetail_1 = require("../ChemDetail/ChemDetail");
const mapStateToProps = state => ({
    dataset: state.dataset
});
const mapDispatchToProps = dispatch => ({});
exports.GenericChanges = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(({ vectorsA, vectorsB, dataset, scale }) => {
    switch (dataset.type) {
        case DatasetType_1.DatasetType.Rubik:
            return React.createElement(RubikChanges_1.RubikChanges, { width: 81 * scale, height: 108 * scale, vectorsA: vectorsA, vectorsB: vectorsB });
        case DatasetType_1.DatasetType.Chess:
            return React.createElement(ChessChanges_1.ChessChanges, { width: 144 * scale, height: 144 * scale, vectorsA: vectorsA, vectorsB: vectorsB });
        case DatasetType_1.DatasetType.Cohort_Analysis:
            return React.createElement(CoralChanges_1.CoralChanges, { width: 80 * scale, height: 80 * scale, vectorsA: vectorsA, vectorsB: vectorsB, scale: scale });
        case DatasetType_1.DatasetType.Chem:
            return React.createElement(ChemDetail_1.ChemLegendParent, { selection: vectorsA, selection_ref: vectorsB, diff: true, aggregate: true, mcs_only: true });
        default:
            return React.createElement(CoralChanges_1.CoralChanges, { width: 80 * scale, height: 80 * scale, vectorsA: vectorsA, vectorsB: vectorsB, scale: scale });
    }
});
