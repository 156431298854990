"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericFingerprint = exports.GenericLegend = void 0;
const NeuralDetail_1 = require("./NeuralDetail/NeuralDetail");
const CoralDetail_1 = require("./CoralDetail/CoralDetail");
const StoryDetail_1 = require("./StoryDetail/StoryDetail");
const GoDetail_1 = require("./GoDetail/GoDetail");
const React = require("react");
const RubikFingerprint_1 = require("./RubikFingerprint/RubikFingerprint");
const ChessFingerprint_1 = require("./ChessFingerprint/ChessFingerprint");
const DatasetType_1 = require("../Utility/Data/DatasetType");
const ChemDetail_1 = require("./ChemDetail/ChemDetail");
//shows single and aggregated view
exports.GenericLegend = ({ type, vectors, aggregate, hoverUpdate, scale = 2 }) => {
    switch (type) {
        case DatasetType_1.DatasetType.Story:
            return React.createElement(StoryDetail_1.StoryLegend, { selection: vectors });
        case DatasetType_1.DatasetType.Rubik:
            return React.createElement(RubikFingerprint_1.RubikFingerprint, { vectors: vectors, width: 81 * scale, height: 108 * scale });
        case DatasetType_1.DatasetType.Neural:
            return React.createElement(NeuralDetail_1.NeuralLegend, { selection: vectors, aggregate: aggregate });
        case DatasetType_1.DatasetType.Chess:
            return React.createElement(ChessFingerprint_1.ChessFingerprint, { width: 144 * scale, height: 144 * scale, vectors: vectors });
        case DatasetType_1.DatasetType.Cohort_Analysis:
            return React.createElement(CoralDetail_1.CoralLegend, { selection: vectors, aggregate: aggregate });
        case DatasetType_1.DatasetType.Go:
            return React.createElement(GoDetail_1.GoLegend, { selection: vectors, aggregate: aggregate });
        case DatasetType_1.DatasetType.Chem:
            return React.createElement(ChemDetail_1.ChemLegendParent, { selection: vectors, aggregate: aggregate, hoverUpdate: hoverUpdate });
        default:
            return React.createElement(CoralDetail_1.CoralLegend, { selection: vectors, aggregate: aggregate });
    }
};
//for storytelling?
exports.GenericFingerprint = ({ type, vectors, scale }) => {
    switch (type) {
        case DatasetType_1.DatasetType.Rubik:
            return React.createElement(RubikFingerprint_1.RubikFingerprint, { width: 81 * scale, height: 108 * scale, vectors: vectors });
        case DatasetType_1.DatasetType.Chess:
            return React.createElement(ChessFingerprint_1.ChessFingerprint, { width: 150 * scale, height: 150 * scale, vectors: vectors });
        case DatasetType_1.DatasetType.Neural:
            return React.createElement(NeuralDetail_1.NeuralLegend, { selection: vectors, aggregate: true });
        case DatasetType_1.DatasetType.Story:
            return React.createElement(StoryDetail_1.StoryLegend, { selection: vectors });
        case DatasetType_1.DatasetType.Cohort_Analysis:
            return React.createElement(CoralDetail_1.CoralLegend, { selection: vectors, aggregate: true });
        case DatasetType_1.DatasetType.Go:
            return React.createElement(GoDetail_1.GoLegend, { selection: vectors, aggregate: true });
        case DatasetType_1.DatasetType.Chem:
            return React.createElement(ChemDetail_1.ChemLegendParent, { selection: vectors, aggregate: true, mcs_only: true });
        default:
            return React.createElement(CoralDetail_1.CoralLegend, { selection: vectors, aggregate: true });
    }
};
