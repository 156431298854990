"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionClusters = void 0;
const React = require("react");
const core_1 = require("@material-ui/core");
const Generic_1 = require("../../legends/Generic");
require("./SelectionClusters.scss");
const react_redux_1 = require("react-redux");
const Vect_1 = require("../../Utility/Data/Vect");
const ReactDOM = require("react-dom");
const HoverSettingsDuck_1 = require("../../Ducks/HoverSettingsDuck");
const WindowPortal_1 = require("../WindowPortal/WindowPortal");
function HoverItemPortal(props) {
    return ReactDOM.createPortal(props.children, document.getElementById("HoverItemDiv"));
}
const SelectionClustersFull = function ({ dataset, currentAggregation, hoverState, hoverSettings, hoverUpdate, setHoverWindowMode }) {
    if (!dataset) {
        return null;
    }
    const genericAggregateLegend = currentAggregation.aggregation && currentAggregation.aggregation.length > 0 ?
        React.createElement(Generic_1.GenericLegend, { aggregate: true, type: dataset.type, vectors: currentAggregation.aggregation, hoverUpdate: hoverUpdate }) :
        React.createElement(core_1.Box, { paddingLeft: 2 },
            React.createElement(core_1.Typography, { color: "textSecondary" }, "Select Points in the Embedding Space to show a Summary Visualization."));
    return React.createElement("div", { className: "Parent" },
        hoverState && hoverState.data && hoverState.data instanceof Vect_1.Vect && React.createElement(HoverItemPortal, null,
            React.createElement(core_1.Card, { elevation: 24, style: {
                    width: 300,
                    maxHeight: '50vh',
                    minHeight: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                } },
                React.createElement(Generic_1.GenericLegend, { aggregate: false, type: dataset.type, vectors: [hoverState.data], hoverUpdate: hoverUpdate }))),
        hoverSettings.windowMode == HoverSettingsDuck_1.WindowMode.Extern ?
            React.createElement(WindowPortal_1.MyWindowPortal, { onClose: () => {
                    setHoverWindowMode(HoverSettingsDuck_1.WindowMode.Embedded);
                } },
                React.createElement("div", { className: "portalSummary", style: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' } }, genericAggregateLegend))
            :
                React.createElement("div", { className: "Cluster" }, genericAggregateLegend));
};
const mapStateToProps = state => ({
    currentAggregation: state.currentAggregation,
    hoverState: state.hoverState,
    dataset: state.dataset,
    hoverSettings: state.hoverSettings,
});
const mapDispatchToProps = dispatch => ({
    setHoverWindowMode: value => dispatch(HoverSettingsDuck_1.setHoverWindowMode(value)),
});
const connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.SelectionClusters = connector(SelectionClustersFull);
