"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryPreview = void 0;
const React = require("react");
const core_1 = require("@material-ui/core");
const react_redux_1 = require("react-redux");
require("./StoryPreview.scss");
const Delete_1 = require("@material-ui/icons/Delete");
const Story_1 = require("../../Utility/Data/Story");
const StoriesDuck_1 = require("../../Ducks/StoriesDuck");
const StoryEditorDuck_1 = require("../../Ducks/StoryEditorDuck");
const mapStateToProps = (state) => ({
    stories: state.stories,
    storyEditor: state.storyEditor
});
const mapDispatchToProps = dispatch => ({
    setActiveStory: activeStory => dispatch(StoriesDuck_1.setActiveStory(activeStory)),
    deleteStory: story => dispatch(StoriesDuck_1.deleteStory(story)),
    addStory: story => dispatch(StoriesDuck_1.addStory(story)),
    openStoryEditor: visible => dispatch(StoryEditorDuck_1.openStoryEditor(visible))
});
const connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.StoryPreview = connector(({ stories, setActiveStory, deleteStory, addStory, storyEditor, openStoryEditor }) => {
    const deleteHandler = (story) => {
        if (stories.active == story) {
            setActiveStory(null);
        }
        deleteStory(story);
    };
    const addHandler = () => {
        addStory(new Story_1.Story([], []));
    };
    return React.createElement("div", { className: "StoryPreviewContent" },
        React.createElement(core_1.FormControl, null,
            React.createElement(core_1.InputLabel, { id: "demo-simple-select-label" }, "Active Story Book"),
            React.createElement(core_1.Select, { labelId: "demo-simple-select-label", id: "demo-simple-select", value: stories.active ? stories.active.getId() : '', onChange: (event) => {
                    setActiveStory(stories.stories.find(story => story.getId() == event.target.value));
                } },
                React.createElement(core_1.ListItem, Object.assign({ key: -1 }, { value: '' }, { button: true }),
                    React.createElement(core_1.ListItemText, { primary: "None" })),
                stories.stories && stories.stories.map((story, key) => {
                    return React.createElement(core_1.ListItem, Object.assign({ key: key, button: true }, { value: story.getId() }),
                        React.createElement(core_1.ListItemText, { primary: "Story Book", secondary: `${story.clusters.length} nodes` }),
                        React.createElement(core_1.ListItemSecondaryAction, null,
                            React.createElement(core_1.IconButton, { edge: "end", "aria-label": "delete", onClick: () => {
                                    deleteHandler(story);
                                } },
                                React.createElement(Delete_1.default, null))));
                }))),
        React.createElement(core_1.Grid, { container: true, direction: "row", alignItems: "center", justify: "space-between" },
            React.createElement(core_1.Button, { style: {
                    marginTop: '16px'
                }, onClick: () => addHandler(), variant: "outlined", size: "small", "aria-label": "move selected left" }, "Add Empty"),
            stories.active && stories.active.clusters.length > 0 && false && React.createElement(core_1.Grid, { item: true },
                React.createElement(core_1.Button, { style: {
                        marginTop: '16px'
                    }, onClick: () => openStoryEditor(!storyEditor.visible), size: "small", variant: "outlined" }, `${storyEditor.visible ? 'Close' : 'Open'} Story Editor`))));
});
