"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDFLoader = void 0;
const DatasetType_1 = require("../Data/DatasetType");
const Vect_1 = require("../Data/Vect");
const CSVLoader_1 = require("./CSVLoader");
const backend_utils = require("../../../utils/backend-connect");
const react_promise_tracker_1 = require("react-promise-tracker");
var d3v5 = require('d3');
function convertFromCSV(vectors) {
    return vectors.map(vector => {
        return new Vect_1.Vect(vector);
    });
}
class SDFLoader {
    constructor() {
        this.loading_area = "global_loading_indicator";
    }
    resolvePath(entry, finished, cancellablePromise, modifiers, abort_controller) {
        if (entry.uploaded) { // use file that is already uploaded to backend
            localStorage.setItem("unique_filename", entry.path);
            this.loadCSV(finished, entry, cancellablePromise, modifiers, abort_controller);
        }
        else {
            react_promise_tracker_1.trackPromise(fetch(entry.path, { signal: abort_controller === null || abort_controller === void 0 ? void 0 : abort_controller.signal }).then(response => response.blob())
                .then(result => this.resolveContent(result, finished, cancellablePromise, modifiers, abort_controller))
                .catch(error => { console.log(error); }), this.loading_area);
        }
    }
    resolveContent(file, finished, cancellablePromise, modifiers, controller) {
        const promise = cancellablePromise ? cancellablePromise(backend_utils.upload_sdf_file(file, controller), controller) : backend_utils.upload_sdf_file(file, controller);
        react_promise_tracker_1.trackPromise(promise.then(() => {
            this.loadCSV(finished, { display: "", type: this.datasetType, path: file.name }, cancellablePromise, modifiers, controller);
        })
            .catch(error => {
            console.log(error);
        }), this.loading_area);
    }
    loadCSV(finished, entry, cancellablePromise, modifiers, controller) {
        // request the server to return a csv file using the unique filename
        let path = backend_utils.BASE_URL + '/get_csv/';
        const filename = localStorage.getItem("unique_filename");
        if (filename !== undefined) {
            path += filename;
        }
        path += "/";
        path += modifiers;
        const promise = cancellablePromise ? cancellablePromise(d3v5.csv(path, { credentials: backend_utils.CREDENTIALS, signal: controller === null || controller === void 0 ? void 0 : controller.signal }), controller) : d3v5.csv(path, { credentials: backend_utils.CREDENTIALS, signal: controller === null || controller === void 0 ? void 0 : controller.signal });
        react_promise_tracker_1.trackPromise(promise.then(vectors => {
            this.vectors = convertFromCSV(vectors);
            this.datasetType = DatasetType_1.DatasetType.Chem;
            new CSVLoader_1.CSVLoader().resolve(finished, this.vectors, this.datasetType, entry);
        })
            .catch(error => { console.log(error); }), this.loading_area);
    }
}
exports.SDFLoader = SDFLoader;
