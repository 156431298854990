"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MySmilesStructureRenderer = exports.StructureImageColumn = exports.LineUpContext = void 0;
const React = require("react");
const react_redux_1 = require("react-redux");
const LineUpJS = require("lineupjs");
require("./LineUpContext.scss");
const AggregationDuck_1 = require("../Ducks/AggregationDuck");
const lineupjs_1 = require("lineupjs");
const backend_utils = require("../../utils/backend-connect");
const FeatureType_1 = require("../Utility/Data/FeatureType");
const Dataset_1 = require("../Utility/Data/Dataset");
const LineUpInputDuck_1 = require("../Ducks/LineUpInputDuck");
const WindowPortal_1 = require("../Overlays/WindowPortal/WindowPortal");
const _ = require("lodash");
const BarCellRenderer_1 = require("./BarCellRenderer");
const DiscreteMapping_1 = require("../Utility/Colors/DiscreteMapping");
const ShallowSet_1 = require("../Utility/ShallowSet");
const Cluster_1 = require("../Utility/Data/Cluster");
/**
 * Declares a function which maps application state to component properties (by name)
 *
 * @param state The whole state of the application (contains a field for each duck!)
 */
const mapStateToProps = (state) => {
    var _a, _b, _c;
    return ({
        lineUpInput: state.lineUpInput,
        lineUpInput_data: (_a = state.dataset) === null || _a === void 0 ? void 0 : _a.vectors,
        lineUpInput_columns: (_b = state.dataset) === null || _b === void 0 ? void 0 : _b.columns,
        currentAggregation: state.currentAggregation,
        activeStory: (_c = state.stories) === null || _c === void 0 ? void 0 : _c.active,
        pointColorScale: state.pointColorScale,
    });
};
/**
 * Declares a function which maps dispatch events to component properties (by name)
 *
 * @param dispatch The generic dispatch function declared in redux
 */
const mapDispatchToProps = dispatch => ({
    setCurrentAggregation: samples => dispatch(AggregationDuck_1.setAggregationAction(samples)),
    setLineUpInput_visibility: visibility => dispatch(LineUpInputDuck_1.setLineUpInput_visibility(visibility)),
    setLineUpInput_lineup: input => dispatch(LineUpInputDuck_1.setLineUpInput_lineup(input)),
});
/**
 * Factory method which is declared here so we can get a static type in 'ConnectedProps'
 */
const connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
const EXCLUDED_COLUMNS = ["__meta__", "x", "y", "algo", "clusterProbability"];
// let lineup = null;
const UPDATER = "lineup";
const UNIQUE_ID = "unique_ID";
/**
 * Our component definition, by declaring our props with 'Props' we have static types for each of our property
 */
exports.LineUpContext = connector(function ({ lineUpInput, lineUpInput_data, lineUpInput_columns, currentAggregation, setCurrentAggregation, setLineUpInput_lineup, setLineUpInput_visibility, onFilter, activeStory, pointColorScale, hoverUpdate }) {
    var _a;
    // In case we have no input, dont render at all
    if (!lineUpInput || !lineUpInput_data || !lineUpInput.show) {
        //splitRef?.current?.setSizes([100, 0])
        return null;
    }
    let lineup_ref = React.useRef();
    const debouncedHighlight = React.useCallback(_.debounce(hover_item => hoverUpdate(hover_item, UPDATER), 200), []);
    const preprocess_lineup_data = (data) => {
        if (activeStory)
            Cluster_1.default.deriveVectorLabelsFromClusters(data, activeStory.clusters);
        let lineup_data = [];
        data.forEach(element => {
            // if(element[PrebuiltFeatures.ClusterLabel].length <= 0){
            //     element[PrebuiltFeatures.ClusterLabel] = [-1];
            // }
            let row = Object.assign({}, element);
            row[Dataset_1.PrebuiltFeatures.ClusterLabel] = element[Dataset_1.PrebuiltFeatures.ClusterLabel].toString();
            row[UNIQUE_ID] = element["__meta__"]["view"]["meshIndex"];
            lineup_data.push(row);
        });
        return lineup_data;
    };
    const clear_automatic_filters = (lineUpInput, filter) => {
        if (filter) {
            for (const key in filter) {
                const lineup = lineUpInput.lineup;
                const ranking = lineup.data.getFirstRanking();
                if (key === 'selection') {
                    const filter_col = ranking.children.find(x => { return x.desc.column == UNIQUE_ID; });
                    filter_col === null || filter_col === void 0 ? void 0 : filter_col.clearFilter();
                }
                else {
                    const filter_col = ranking.children.find(x => { return x.desc.column == key; });
                    filter_col === null || filter_col === void 0 ? void 0 : filter_col.clearFilter();
                }
            }
        }
    };
    const get_lineup_dump = (lineUpInput) => {
        if (lineUpInput.lineup) {
            clear_automatic_filters(lineUpInput, lineUpInput.filter);
            const dump = lineUpInput.lineup.dump();
            return dump;
        }
        return null;
    };
    React.useEffect(() => {
        // if(lineUpInput.dump){
        //     try {
        //         const json_parsed = JSON.parse(lineUpInput.dump)
        //         const restored = fromDumpFile(json_parsed)
        //         console.log(restored);
        //         const builder = buildLineup(lineUpInput.columns, restored.dat).restore(restored.dump);
        //         // const builder = LineUpJS.builder(restored.data).restore(restored.dump);
        //         lineup?.destroy();
        //         lineup = builder.build(lineup_ref.current);
        //         return;
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }
        var _a;
        let lineup_data = preprocess_lineup_data(lineUpInput_data);
        const builder = buildLineup(lineUpInput_columns, lineup_data, pointColorScale); //lineUpInput_data
        let dump = get_lineup_dump(lineUpInput);
        (_a = lineUpInput.lineup) === null || _a === void 0 ? void 0 : _a.destroy();
        let lineup = null;
        lineup = builder.build(lineup_ref.current);
        if (dump) {
            lineup.restore(dump);
        }
        const ranking = lineup.data.getFirstRanking();
        // add selection checkbox column
        let selection_col = ranking.children.find(x => x.label == "Selection Checkboxes");
        if (!selection_col) {
            selection_col = lineup.data.create(lineupjs_1.createSelectionDesc());
            if (selection_col) {
                ranking.insert(selection_col, 1);
            }
        }
        // // make lineup filter interact with the scatter plot view
        // ranking.on('orderChanged.custom', (previous, current, previousGroups, currentGroups, dirtyReason) => {
        //     if (dirtyReason.indexOf('filter') === -1) {
        //         return;
        //     }
        //     const onRankingChanged = (current) => {
        //         for (let i=0; i < lineUpInput.data.length; i++) {
        //             lineUpInput.data[i].view.lineUpFiltered = !current.includes(i);
        //         }
        //         onFilter()
        //     }
        //     onRankingChanged(current)
        // });
        // make lineup selection interact with the scatter plot view
        lineup.on('selectionChanged', currentSelection_lineup => {
            // if(currentSelection_lineup.length == 0) return; // selectionChanged is called during creation of lineup, before the current aggregation was set; therefore, it would always set the current aggregation to nothing because in the lineup table nothing was selected yet
            const currentSelection_scatter = lineUpInput_data.map((x, i) => { if (x.view.selected)
                return i; }).filter(x => x !== undefined);
            if (!arrayEquals(currentSelection_lineup, currentSelection_scatter)) { // need to check, if the current lineup selection is already the current aggregation
                let agg = [];
                currentSelection_lineup.forEach(index => {
                    agg.push(lineUpInput_data[index]);
                });
                setCurrentAggregation(agg);
            }
        });
        lineup.on('highlightChanged', idx => {
            let hover_item = null;
            if (idx >= 0) {
                hover_item = lineUpInput_data[idx];
            }
            debouncedHighlight(hover_item);
        });
        // update lineup when smiles_column width changes
        if (smiles_structure_columns && smiles_structure_columns.length > 0) {
            const lineup_smiles_cols = ranking.children.filter(x => smiles_structure_columns.includes(x.label));
            for (const i in lineup_smiles_cols) {
                let lineup_smiles_col = lineup_smiles_cols[i];
                lineup_smiles_col.on("widthChanged", (prev, current) => {
                    lineup.update();
                });
                // custom filter adapted from michael
                const filterChanged = (prev, cur) => {
                    if ((prev === null || prev === void 0 ? void 0 : prev.filter) !== (cur === null || cur === void 0 ? void 0 : cur.filter)) { // only update, if it is a new filter
                        const filter = typeof (cur === null || cur === void 0 ? void 0 : cur.filter) === 'string' ? cur === null || cur === void 0 ? void 0 : cur.filter : null; // only allow string filters -> no regex (TODO: remove regex checkbox)
                        if (lineup_smiles_col && filter) {
                            backend_utils.get_substructure_count(lineUpInput_data.map((d) => d[lineup_smiles_col.desc.column]), filter).then((matches) => {
                                const validSmiles = matches.filter(([smiles, count]) => count > 0).map(([smiles, count]) => smiles);
                                lineup_smiles_col.setFilter({
                                    filter,
                                    valid: new Set(validSmiles),
                                    filterMissing: cur.filterMissing
                                });
                            }).catch((e) => {
                                lineup_smiles_col.setFilter(null);
                            });
                        }
                    }
                };
                lineup_smiles_col.on(lineupjs_1.StringColumn.EVENT_FILTER_CHANGED, filterChanged);
            }
        }
        setLineUpInput_lineup(lineup);
    }, [lineUpInput_data, lineUpInput_columns, activeStory, activeStory === null || activeStory === void 0 ? void 0 : activeStory.clusters, (_a = activeStory === null || activeStory === void 0 ? void 0 : activeStory.clusters) === null || _a === void 0 ? void 0 : _a.length, lineUpInput.update]);
    // React.useEffect(() => { //TODO: not working...
    //     // update lineup, if current storybook (current cluster) changed
    //     if(lineUpInput.lineup){
    //         const data_provider = lineUpInput.lineup.data;
    //         let lineup_data = preprocess_lineup_data(lineUpInput_data);
    //         console.log("setdata")
    //         data_provider.setData(lineup_data);
    //         const ranking = lineUpInput.lineup.data.getFirstRanking();
    //         const my_col_builder = LineUpJS.buildCategoricalColumn(PrebuiltFeatures.ClusterLabel);
    //         console.log(lineup_data)
    //          ranking.insert(lineUpInput.lineup.data.create(my_col_builder.build(lineup_data)));
    //         ranking.insert(lineUpInput.lineup.data.create(my_col_builder.build([]])));
    //         // const ranking = lineUpInput.lineup.data.getFirstRanking();
    //         // // let cluster_col = ranking.columns.find(x => x.desc.column == PrebuiltFeatures.ClusterLabel);
    //         // // const my_desc = cluster_col.desc;
    //         // // my_desc.categories = ["test"]
    //         // // const my_col = new CategoricalColumn(cluster_col.id, cluster_col.desc)
    //         // const my_col_builder = LineUpJS.buildCategoricalColumn(PrebuiltFeatures.ClusterLabel);
    //         // // console.log()
    //         // ranking.insert(lineUpInput.lineup.data.create(my_col_builder.build(lineup_data))); //asSet(',')
    //         // // data_provider.setData(lineup_data)
    //         // // lineUpInput.lineup.update();
    //         // // lineUpInput.lineup?.setDataProvider(data_provider);
    //         // lineUpInput.lineup.restore(lineUpInput.lineup.dump())
    //         // // console.log(cluster_col.dump())
    //         // // console.log(lineUpInput.lineup.dump())
    //     }
    // }, [activeStory, activeStory?.clusters, activeStory?.clusters?.length]);
    // this effect is allways executed after the component is rendered when currentAggregation changed
    React.useEffect(() => {
        if (lineUpInput.lineup != null) {
            // select those instances that are also selected in the scatter plot view
            if (currentAggregation.aggregation && currentAggregation.aggregation.length > 0) {
                const currentSelection_scatter = lineUpInput_data.map((x, i) => { if (x.view.selected)
                    return i; }).filter(x => x !== undefined);
                lineUpInput.lineup.setSelection(currentSelection_scatter);
                // const lineup_idx = lineup.renderer?.rankings[0]?.findNearest(currentSelection_scatter);
                // lineup.renderer?.rankings[0]?.scrollIntoView(lineup_idx);
                // set the grouping to selection checkboxes -> uncomment if this should be automatically if something changes
                // const ranking = lineup.data.getFirstRanking();
                // let selection_col = ranking.children.find(x => x.label == "Selection Checkboxes");
                // ranking.groupBy(selection_col, -1) // remove grouping first
                // ranking.groupBy(selection_col);
            }
            else {
                lineUpInput.lineup.setSelection([]);
            }
        }
    }, [lineUpInput.lineup, currentAggregation]);
    React.useEffect(() => {
        if (lineUpInput.lineup && lineUpInput.lineup.data) {
            const ranking = lineUpInput.lineup.data.getFirstRanking();
            clear_automatic_filters(lineUpInput, lineUpInput.previousfilter);
            if (lineUpInput.filter) {
                for (const key in lineUpInput.filter) {
                    const cur_filter = lineUpInput.filter[key];
                    if (key === 'reset' && cur_filter) {
                        ranking.clearFilters();
                    }
                    else if (key === 'selection') {
                        const filter_col = ranking.children.find(x => { return x.desc.column == UNIQUE_ID; });
                        let regex_str = "";
                        lineUpInput.filter[key].forEach(element => {
                            regex_str += "|";
                            regex_str += element["__meta__"]["view"]["meshIndex"];
                        });
                        regex_str = regex_str.substr(1); // remove the leading "|"
                        const my_regex = new RegExp(`^(${regex_str})$`, "i"); // i modifier says that it's not case sensitive; ^ means start of string; $ means end of string
                        filter_col === null || filter_col === void 0 ? void 0 : filter_col.setFilter({
                            filter: my_regex,
                            filterMissing: true
                        });
                    }
                    else {
                        const filter_col = ranking.children.find(x => { return x.desc.column == key; });
                        const my_regex = new RegExp(`^(.+,)?${cur_filter}(,.+)?$`, "i"); // i modifier says that it's not case sensitive; ^ means start of string; $ means end of string
                        filter_col === null || filter_col === void 0 ? void 0 : filter_col.setFilter({
                            filter: my_regex,
                            filterMissing: true
                        });
                    }
                }
            }
        }
    }, [lineUpInput.lineup, lineUpInput.filter]);
    //https://github.com/lineupjs/lineup_app/blob/master/src/export.ts
    return false ?
        React.createElement(WindowPortal_1.MyWindowPortal, { onClose: () => { var _a; (_a = lineUpInput.lineup) === null || _a === void 0 ? void 0 : _a.destroy(); setLineUpInput_visibility(false); } },
            React.createElement("div", { ref: lineup_ref, id: "lineup_view" })) :
        React.createElement("div", { className: "LineUpParent" },
            React.createElement("div", null,
                React.createElement("div", { ref: lineup_ref, id: "lineup_view" })));
});
let smiles_structure_columns = [];
function myDynamicHeight(data, ranking) {
    if (smiles_structure_columns.length > 0) {
        const cols = ranking.children.filter(x => smiles_structure_columns.includes(x.label));
        if (!cols || cols.length == 0)
            return null;
        const col_widths = cols.map(x => x.getWidth());
        const col_width = Math.max(Math.max(...col_widths), 23); //col.getWidth();
        let height = function (item) {
            return col_width;
        };
        let padding = function (item) {
            return 0;
        };
        return { defaultHeight: col_width, height: height, padding: padding };
    }
    return { defaultHeight: 23, height: () => 23, padding: () => 0 };
}
function buildLineup(cols, data, pointColorScale) {
    let groupLabel_mapping = new DiscreteMapping_1.DiscreteMapping(pointColorScale, new ShallowSet_1.ShallowSet(data.map(vector => vector[Dataset_1.PrebuiltFeatures.ClusterLabel])));
    const groupLabel_cat_color = groupLabel_mapping.values
        .filter(cat => cat && cat !== "")
        .map(cat => {
        return { name: cat, color: groupLabel_mapping.map(cat).hex };
    });
    const builder = LineUpJS.builder(data);
    for (const i in cols) {
        let col = cols[i];
        let show = true; //!(typeof col.metaInformation.hideLineUp !== 'undefined' && col.metaInformation.hideLineUp); // hide column if "hideLineUp" is specified -> there is a lineup bug with that option
        if (!EXCLUDED_COLUMNS.includes(i) && (Object.keys(col.metaInformation).length <= 0 || !col.metaInformation.noLineUp)) { // only if there is a "noLineUp" modifier at this column or thix column is excluded, we don't do anything
            if (col.metaInformation.imgSmiles) {
                const smiles_col = "Structure: " + i;
                smiles_structure_columns.push(smiles_col);
                builder.column(LineUpJS.buildColumn("mySmilesStructureColumn", i).label(smiles_col).renderer("mySmilesStructureRenderer", "mySmilesStructureRenderer").width(50).build([]));
            }
            if (i == Dataset_1.PrebuiltFeatures.ClusterLabel) {
                const clust_col = LineUpJS.buildCategoricalColumn(i, groupLabel_cat_color).custom("visible", show).width(70); // .asSet(',')
                builder.column(clust_col);
            }
            else if (typeof col.featureType !== 'undefined') {
                switch (col.featureType) {
                    case FeatureType_1.FeatureType.Categorical:
                        if (data && col.distinct && col.distinct.length / data.length <= 0.5) {
                            builder.column(LineUpJS.buildCategoricalColumn(i).custom("visible", show));
                        }
                        else {
                            builder.column(LineUpJS.buildStringColumn(i).width(50).custom("visible", show));
                        }
                        break;
                    case FeatureType_1.FeatureType.Quantitative:
                        builder.column(LineUpJS.buildNumberColumn(i).numberFormat(".2f").custom("visible", show)); //.renderer("myBarCellRenderer")); //.renderer("numberWithValues")
                        break;
                    case FeatureType_1.FeatureType.Date:
                        builder.column(LineUpJS.buildDateColumn(i).custom("visible", show));
                        break;
                    default:
                        builder.column(LineUpJS.buildStringColumn(i).width(50).custom("visible", show));
                        break;
                }
            }
            else {
                if (col.isNumeric) {
                    builder.column(LineUpJS.buildNumberColumn(i, [col.range.min, col.range.max]).numberFormat(".2f").custom("visible", show)); //.renderer("myBarCellRenderer"));
                }
                else if (col.distinct)
                    if (data && col.distinct.length / data.length <= 0.5) // if the ratio between distinct categories and nr of data points is less than 1:2, the column is treated as a string
                        builder.column(LineUpJS.buildCategoricalColumn(i).custom("visible", show));
                    else
                        builder.column(LineUpJS.buildStringColumn(i).width(50).custom("visible", show));
                else
                    builder.column(LineUpJS.buildStringColumn(i).width(50).custom("visible", show));
            }
        }
    }
    builder.column(LineUpJS.buildStringColumn("Annotations").editable());
    builder.column(LineUpJS.buildStringColumn(UNIQUE_ID).width(50)); // we need this to be able to filter by all indices; this ID corresponds to the mesh index
    builder.defaultRanking(true);
    builder.deriveColors();
    builder.registerRenderer("mySmilesStructureRenderer", new MySmilesStructureRenderer());
    builder.registerRenderer("myBarCellRenderer", new BarCellRenderer_1.default(true));
    builder.registerColumnType("mySmilesStructureColumn", StructureImageColumn);
    builder.sidePanel(true, true); // collapse side panel by default
    builder.livePreviews({
        filter: false
    });
    builder.dynamicHeight(myDynamicHeight);
    builder.animated(false);
    return builder;
}
class StructureImageColumn extends lineupjs_1.StringColumn {
    constructor() {
        super(...arguments);
        this.structureFilter = null;
    }
    filter(row) {
        if (!this.isFiltered()) {
            return true;
        }
        return this.structureFilter.valid.has(this.getLabel(row));
    }
    isFiltered() {
        var _a;
        return this.structureFilter != null && ((_a = this.structureFilter.valid) === null || _a === void 0 ? void 0 : _a.size) > 0;
    }
    getFilter() {
        return this.structureFilter;
    }
    setFilter(filter) {
        if (lineupjs_1.equal(filter, this.structureFilter)) {
            return;
        }
        this.fire([lineupjs_1.StringColumn.EVENT_FILTER_CHANGED, lineupjs_1.Column.EVENT_DIRTY_VALUES, lineupjs_1.Column.EVENT_DIRTY], this.structureFilter, this.structureFilter = filter);
    }
}
exports.StructureImageColumn = StructureImageColumn;
class MySmilesStructureRenderer {
    constructor() {
        this.title = 'Compound Structure';
        // better with background image, because with img tag the user might drag the img when they actually want to select several rows
        this.template = '<div style="background-size: contain; background-position: center; background-repeat: no-repeat;"></div>';
    }
    canRender(col, mode) {
        return col instanceof StructureImageColumn && (mode === lineupjs_1.ERenderMode.CELL || mode === lineupjs_1.ERenderMode.GROUP);
    }
    create(col) {
        return {
            template: this.template,
            update: (n, d) => {
                // @ts-ignore
                let smiles = d.v[col.desc.column];
                backend_utils.get_structure_from_smiles(smiles)
                    .then(x => {
                    if (x.length > 100) { // check if it is actually long enogh to be an img
                        n.style.backgroundImage = `url('data:image/jpg;base64,${x}')`;
                    }
                    else {
                        n.innerHTML = x;
                    }
                    n.alt = smiles;
                });
            }
        };
    }
    createGroup(col, context) {
        return {
            template: this.template,
            update: (n, group) => {
                const formData = new FormData();
                return context.tasks.groupRows(col, group, 'string', (rows) => {
                    rows.every((row) => {
                        const v = col.getLabel(row);
                        formData.append('smiles_list', v);
                        return true;
                    });
                })
                    .then(() => {
                    backend_utils.get_mcs_from_smiles_list(formData)
                        .then(x => {
                        n.style.backgroundImage = `url('data:image/jpg;base64,${x}')`;
                        n.alt = formData.getAll("smiles_list").toString();
                    });
                });
            }
        };
    }
}
exports.MySmilesStructureRenderer = MySmilesStructureRenderer;
