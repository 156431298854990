"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrightnessSlider = void 0;
const React = require("react");
const core_1 = require("@material-ui/core");
require("./BrightnessSlider.scss");
const react_redux_1 = require("react-redux");
const GlobalPointBrightnessDuck_1 = require("../../../Ducks/GlobalPointBrightnessDuck");
const BrightnessSliderFull = ({ brightnessScale, setRange }) => {
    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 0.25,
            label: `0.25`,
        },
        {
            value: 0.5,
            label: `0.5`,
        },
        {
            value: 0.75,
            label: `0.75`,
        },
        {
            value: 1,
            label: `1`,
        }
    ];
    return React.createElement("div", { className: "BrightnessSliderParent" },
        React.createElement(core_1.Typography, { id: "range-slider", gutterBottom: true }, "Brightness Scale"),
        React.createElement(core_1.Slider, { min: 0, max: 1, value: brightnessScale, onChange: (_, newValue) => setRange(newValue), step: 0.05, marks: marks, valueLabelDisplay: "auto" }));
};
const mapStateToProps = (state) => ({
    brightnessScale: state.globalPointBrightness
});
const mapDispatchToProps = dispatch => ({
    setRange: value => dispatch(GlobalPointBrightnessDuck_1.setGlobalPointBrightness(value))
});
exports.BrightnessSlider = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(BrightnessSliderFull);
