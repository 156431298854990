"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAggregationGroups = exports.aggSelectCluster = exports.setAggregationAction = exports.toggleAggregationAction = void 0;
const SELECT_SAMPLES = "ducks/aggregation/SET";
const SELECT_GROUPS = "ducks/aggregation/SELECT_CLUSTER";
const TOGGLE = "ducks/aggregation/TOGGLE";
const MERGE = "ducks/aggregation/MERGE";
const SET_AVAILABLE_GROUPS = "ducks/aggregation/SET_GROUPS";
exports.toggleAggregationAction = aggregation => ({
    type: TOGGLE,
    aggregation: aggregation
});
exports.setAggregationAction = samples => ({
    type: SELECT_SAMPLES,
    aggregation: samples
});
exports.aggSelectCluster = (cluster, shiftKey) => ({
    type: SELECT_GROUPS,
    cluster: cluster,
    shiftKey: shiftKey
});
exports.setAggregationGroups = (groups) => ({
    type: SET_AVAILABLE_GROUPS,
    groups: groups
});
function deriveFromClusters(clusters) {
    let agg = clusters.map(cluster => cluster.vectors).flat();
    return [...new Set(agg)];
}
function deriveFromSamples(samples, clusters) {
    let labels = new Set();
    samples.forEach(sample => {
        sample.groupLabel.forEach(label => {
            labels.add(label);
        });
    });
    let arr = Array.from(labels);
    return clusters.filter(cluster => {
        return arr.includes(cluster.label);
    });
}
const initialState = {
    aggregation: [],
    selectedClusters: [],
    groups: [],
    source: 'sample'
};
const currentAggregation = (state = initialState, action) => {
    switch (action.type) {
        case SET_AVAILABLE_GROUPS:
            return Object.assign(Object.assign({}, state), { groups: action.groups });
        case SELECT_SAMPLES:
            return {
                aggregation: action.aggregation,
                selectedClusters: deriveFromSamples(action.aggregation, state.groups),
                groups: state.groups,
                source: 'sample'
            };
        case TOGGLE: {
            let newState = state.aggregation.slice(0);
            action.aggregation.forEach(vector => {
                if (newState.includes(vector)) {
                    newState.splice(newState.indexOf(vector), 1);
                }
                else {
                    newState.push(vector);
                }
            });
            return {
                aggregation: newState,
                selectedClusters: deriveFromSamples(newState, state.groups),
                groups: state.groups,
                source: 'sample'
            };
        }
        case MERGE: {
            let newState = state.aggregation.slice(0);
            action.samples.forEach(sample => {
                if (!sample.view.selected) {
                    newState.push(sample);
                }
            });
            return {
                aggregation: newState,
                selectedClusters: deriveFromSamples(newState, state.groups),
                groups: state.groups,
                source: 'sample'
            };
        }
        case SELECT_GROUPS: {
            if (action.shiftKey) {
                var newState = state.selectedClusters.slice(0);
                if (newState.includes(action.cluster)) {
                    newState.splice(newState.indexOf(action.cluster), 1);
                }
                else {
                    newState.push(action.cluster);
                }
                return {
                    aggregation: deriveFromClusters(newState),
                    selectedClusters: newState,
                    groups: state.groups,
                    source: 'cluster'
                };
            }
            else {
                return {
                    selectedClusters: [action.cluster],
                    aggregation: [...action.cluster.vectors],
                    groups: state.groups,
                    source: 'cluster'
                };
            }
        }
        default:
            return state;
    }
};
exports.default = currentAggregation;
