"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatasetDatabase = void 0;
const DatasetType_1 = require("./DatasetType");
/**
 * Dummy class that holds information about the files that can be preselected.
 */
class DatasetDatabase {
    constructor() {
        this.data = [
            {
                display: "Chess: 190 Games",
                path: "datasets/chess/chess16k.csv",
                type: DatasetType_1.DatasetType.Chess
            },
            {
                display: "Chess: 450 Games",
                path: "datasets/chess/chess40k.csv",
                type: DatasetType_1.DatasetType.Chess
            },
            {
                display: "Chess: 450 Games (Groups)",
                path: "datasets/chess/chess40k_groups.json",
                type: DatasetType_1.DatasetType.Chess
            },
            {
                display: "Chess: AlphaZero vs Stockfish",
                path: "datasets/chess/alphazero.csv",
                type: DatasetType_1.DatasetType.Chess
            },
            {
                display: "Rubik: 1x2 Different Origins",
                path: "datasets/rubik/cube1x2_different_origins.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "Rubik: 1x2 Same Origins",
                path: "datasets/rubik/cube1x2.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "Rubik: 10x2 Different Origins",
                path: "datasets/rubik/cube10x2_different_origins.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "Rubik: 10x2 Same Origins",
                path: "datasets/rubik/cube10x2.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "Rubik: 100x2 Different Origins",
                path: "datasets/rubik/cube100x2_different_origins.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "Rubik: 100x2 Same Origins",
                path: "datasets/rubik/cube100x2.csv",
                type: DatasetType_1.DatasetType.Rubik
            },
            {
                display: "NN: Rnd Weights",
                path: "datasets/neural/random_weights.csv",
                type: DatasetType_1.DatasetType.Neural
            },
            {
                display: "NN: Rnd Confusion Matrix",
                path: "datasets/neural/random_confmat.csv",
                type: DatasetType_1.DatasetType.Neural
            },
            {
                display: "NN: Weights",
                path: "datasets/neural/learning_weights.csv",
                type: DatasetType_1.DatasetType.Neural
            },
            {
                display: "NN: Confusion Matrix",
                path: "datasets/neural/learning_confmat.csv",
                type: DatasetType_1.DatasetType.Neural
            },
            {
                display: "Story: With Names",
                path: "datasets/story/withnames.csv",
                type: DatasetType_1.DatasetType.Story
            },
            {
                display: "Story: No Duplicates",
                path: "datasets/story/stories_dup-del_p50_with-names.csv",
                type: DatasetType_1.DatasetType.Story
            },
            {
                display: "Story: Test",
                path: "datasets/story/teststories.csv",
                type: DatasetType_1.DatasetType.Story
            },
            {
                display: "Go: State features",
                path: "datasets/go/combined.csv",
                type: DatasetType_1.DatasetType.Go
            },
            {
                display: "Go: Histogram features",
                path: "datasets/go/histogram.csv",
                type: DatasetType_1.DatasetType.Go
            },
            {
                display: "Go: Move features (wavelet)",
                path: "datasets/go/move_wavelet.csv",
                type: DatasetType_1.DatasetType.Go
            },
            {
                display: "Toy: Iris",
                path: "datasets/toy/iris.csv",
                type: DatasetType_1.DatasetType.None
            },
            {
                display: "Toy: Story",
                path: "datasets/toy/toy.csv",
                type: DatasetType_1.DatasetType.None
            },
            {
                display: "Penguins",
                path: "datasets/penguins/penguins_without_nan.csv",
                type: DatasetType_1.DatasetType.None
            },
            {
                display: "CIME: Test",
                path: "test.sdf",
                type: DatasetType_1.DatasetType.Chem,
                uploaded: true
            },
            {
                display: "Cohort: TCGA Sub-sampled",
                path: "datasets/coral/coral_subsampled_normalized_no_one_hot.json",
                type: DatasetType_1.DatasetType.Cohort_Analysis
            },
            {
                display: "Cohort: TCGA Lung, Colorectal, and Pancreatic Cancer",
                path: "datasets/coral/coral_usecase_3TumorTypes_expression_normalized_no_one_hot.json",
                type: DatasetType_1.DatasetType.Cohort_Analysis
            },
        ];
    }
    getTypes() {
        return [...new Set(this.data.map(value => value.type))];
    }
    getByPath(path) {
        return this.data.filter(e => e.path == path)[0];
    }
}
exports.DatasetDatabase = DatasetDatabase;
