"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataLineView = exports.DataLine = void 0;
/**
 * Main data class for lines
 */
class DataLine {
    constructor(lineKey, vectors) {
        this.lineKey = lineKey;
        this.vectors = vectors;
        this.__meta__ = {};
        this.setMeta('view', new DataLineView());
    }
    /**
     * Sets some meta data for a key
     */
    setMeta(key, value) {
        this.__meta__[key] = value;
    }
    /**
     * Gets some meta data for a key
     */
    getMeta(key) {
        return this.__meta__[key];
    }
    /**
     * Getter for view details
     */
    get view() {
        return this.getMeta('view');
    }
}
exports.DataLine = DataLine;
/**
 * View information for segments
 */
class DataLineView {
    constructor() {
        /**
         * Determines if this line should be grayed out
         */
        this.grayed = false;
        /**
         * Is this segment visible through the detailed selection? (line selection treeview)
         */
        this.detailVisible = true;
        /**
         * Is this segment visible through the global switch?
         */
        this.globalVisible = true;
        /**
         * Is this segment currently highlighted?
         */
        this.highlighted = false;
        /**
         * Color set for this line
         */
        this.intrinsicColor = null;
        /**
         * Line mesh
         */
        this.lineMesh = null;
    }
}
exports.DataLineView = DataLineView;
