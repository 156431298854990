"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setClusterEdgesAction = void 0;
const SET = "ducks/clusterEdges/SET";
exports.setClusterEdgesAction = clusterEdges => ({
    type: SET,
    clusterEdges: clusterEdges
});
const clusterEdges = (state = [], action) => {
    switch (action.type) {
        case SET:
            return action.clusterEdges;
        default:
            return state;
    }
};
exports.default = clusterEdges;
