"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolSelectionRedux = exports.getToolCursor = exports.Tool = void 0;
const React = require("react");
const ToggleButtonGroup_1 = require("@material-ui/lab/ToggleButtonGroup");
const ToggleButton_1 = require("@material-ui/lab/ToggleButton");
const ControlCamera_1 = require("@material-ui/icons/ControlCamera");
const SelectAll_1 = require("@material-ui/icons/SelectAll");
require("./ToolSelection.scss");
const react_redux_1 = require("react-redux");
const LinearScale_1 = require("@material-ui/icons/LinearScale");
const core_1 = require("@material-ui/core");
const CurrentToolDuck_1 = require("../../Ducks/CurrentToolDuck");
const frontend_utils = require("../../../utils/frontend-connect");
const ENTER_DELAY = 500;
var Tool;
(function (Tool) {
    Tool[Tool["Default"] = 0] = "Default";
    Tool[Tool["Move"] = 1] = "Move";
    Tool[Tool["Crosshair"] = 2] = "Crosshair";
})(Tool = exports.Tool || (exports.Tool = {}));
function getToolCursor(tool) {
    switch (tool) {
        case Tool.Default:
            return 'default';
        case Tool.Move:
            return 'move';
        case Tool.Crosshair:
            return 'crosshair';
    }
}
exports.getToolCursor = getToolCursor;
const mapStateToProps = (state) => ({
    currentTool: state.currentTool,
    dataset: state.dataset
});
const mapDispatchToProps = dispatch => ({
    setCurrentTool: id => dispatch(CurrentToolDuck_1.setCurrentTool(id))
});
const connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
function ToolSelection({ currentTool, setCurrentTool, dataset }) {
    return React.createElement("div", { className: "ToolSelectionParent" },
        React.createElement(ToggleButtonGroup_1.default, { style: { pointerEvents: 'auto' }, size: 'small', value: currentTool, exclusive: true, onChange: (e, newValue) => {
                setCurrentTool(newValue);
            }, "aria-label": "text alignment" },
            React.createElement(ToggleButton_1.default, { value: Tool.Default },
                React.createElement(core_1.Tooltip, { enterDelay: ENTER_DELAY, title: React.createElement(React.Fragment, null,
                        React.createElement(core_1.Typography, { variant: "subtitle2" }, "Lasso Selection Tool"),
                        React.createElement(core_1.Typography, { variant: "body2" }, "This tool can be used to make a lasso selection around states which selects or deselects them.")) },
                    React.createElement(SelectAll_1.default, null))),
            React.createElement(ToggleButton_1.default, { value: Tool.Move },
                React.createElement(core_1.Tooltip, { enterDelay: ENTER_DELAY, title: React.createElement(React.Fragment, null,
                        React.createElement(core_1.Typography, { variant: "subtitle2" }, "Panning Tool"),
                        React.createElement(core_1.Typography, { variant: "body2" }, "With this tool you can move the projection around by dragging it.")) },
                    React.createElement(ControlCamera_1.default, null))),
            (dataset === null || dataset === void 0 ? void 0 : dataset.isSequential) && !frontend_utils.CHEM_PROJECT &&
                React.createElement(ToggleButton_1.default, { value: Tool.Crosshair },
                    React.createElement(core_1.Tooltip, { enterDelay: ENTER_DELAY, title: React.createElement(React.Fragment, null,
                            React.createElement(core_1.Typography, { variant: "subtitle2" }, "Line Inspection Tool"),
                            React.createElement(core_1.Typography, { variant: "body2" }, "When clicking on a state of a line while this tool is active, the line will become selected and you can inspect it state by state.")) },
                        React.createElement(LinearScale_1.default, null)))));
}
exports.ToolSelectionRedux = connector(ToolSelection);
