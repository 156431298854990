"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("regenerator-runtime/runtime");
const Typography_1 = require("@material-ui/core/Typography");
const WebGLView_1 = require("./WebGLView/WebGLView");
const Grid_1 = require("@material-ui/core/Grid");
const NamedCategoricalScales_1 = require("./Utility/Colors/NamedCategoricalScales");
const ContinuousMapping_1 = require("./Utility/Colors/ContinuousMapping");
const DiscreteMapping_1 = require("./Utility/Colors/DiscreteMapping");
const ContinuosScale_1 = require("./Utility/Colors/ContinuosScale");
const core_1 = require("@material-ui/core");
const DatasetDatabase_1 = require("./Utility/Data/DatasetDatabase");
const LineTreePopover_1 = require("./DrawerTabPanels/StatesTabPanel/LineTreePopover/LineTreePopover");
const Box_1 = require("@material-ui/core/Box");
const Tabs_1 = require("@material-ui/core/Tabs");
const Tab_1 = require("@material-ui/core/Tab");
const React = require("react");
const Storytelling_1 = require("./Overlays/ClusterOverview/Storytelling");
const ReactDOM = require("react-dom");
const ClusteringTabPanel_1 = require("./DrawerTabPanels/ClusteringTabPanel/ClusteringTabPanel");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const react_redux_2 = require("react-redux");
const StatesTabPanel_1 = require("./DrawerTabPanels/StatesTabPanel/StatesTabPanel");
const StateSequenceDrawer_1 = require("./Overlays/StateSequenceDrawer/StateSequenceDrawer");
const ProjectionOpenDuck_1 = require("./Ducks/ProjectionOpenDuck");
const DatasetDuck_1 = require("./Ducks/DatasetDuck");
const OpenTabDuck_1 = require("./Ducks/OpenTabDuck");
const WebGLViewDuck_1 = require("./Ducks/WebGLViewDuck");
const ClusterModeDuck_1 = require("./Ducks/ClusterModeDuck");
const AdvancedColoringSelectionDuck_1 = require("./Ducks/AdvancedColoringSelectionDuck");
const CategoryOptions_1 = require("./WebGLView/CategoryOptions");
const ProjectionColumnsDuck_1 = require("./Ducks/ProjectionColumnsDuck");
const EmbeddingTabPanel_1 = require("./DrawerTabPanels/EmbeddingTabPanel/EmbeddingTabPanel");
const CSVLoader_1 = require("./Utility/Loaders/CSVLoader");
const StoryEditor_1 = require("./Overlays/StoryEditor/StoryEditor");
const ActiveLineDuck_1 = require("./Ducks/ActiveLineDuck");
const PathLengthRange_1 = require("./Ducks/PathLengthRange");
const CategoryOptionsDuck_1 = require("./Ducks/CategoryOptionsDuck");
const ChannelSize_1 = require("./Ducks/ChannelSize");
const GlobalPointSizeDuck_1 = require("./Ducks/GlobalPointSizeDuck");
const ChannelColorDuck_1 = require("./Ducks/ChannelColorDuck");
const Store_1 = require("./Store/Store");
const DatasetTabPanel_1 = require("./DrawerTabPanels/DatasetTabPanel/DatasetTabPanel");
const LineUpContext_1 = require("./LineUpContext/LineUpContext");
const redux_devtools_extension_1 = require("redux-devtools-extension");
const LineUpInputDuck_1 = require("./Ducks/LineUpInputDuck");
const SDFLoader_1 = require("./Utility/Loaders/SDFLoader");
const frontend_utils = require("../utils/frontend-connect");
const DetailsTabPanel_1 = require("./DrawerTabPanels/DetailsTabPanel/DetailsTabPanel");
const ProjectionsDuck_1 = require("./Ducks/ProjectionsDuck");
const Embedding_1 = require("./Utility/Data/Embedding");
const StoriesDuck_1 = require("./Ducks/StoriesDuck");
// @ts-ignore
const pse_icon_dataset_svg_1 = require("./Icons/pse-icon-dataset.svg");
// @ts-ignore
const pse_icon_clusters_svg_1 = require("./Icons/pse-icon-clusters.svg");
// @ts-ignore
const pse_icon_details_svg_1 = require("./Icons/pse-icon-details.svg");
// @ts-ignore
const pse_icon_encoding_svg_1 = require("./Icons/pse-icon-encoding.svg");
// @ts-ignore
const pse_icon_project_svg_1 = require("./Icons/pse-icon-project.svg");
// @ts-ignore
const pse_icon_lineup_svg_1 = require("./Icons/pse-icon-lineup.svg");
require("./index.scss");
const react_split_1 = require("react-split");
const SelectedLineByDuck_1 = require("./Ducks/SelectedLineByDuck");
const LineUpTabPanel_1 = require("./DrawerTabPanels/LineUpTabPanel/LineUpTabPanel");
const SplitRefDuck_1 = require("./Ducks/SplitRefDuck");
const Story_1 = require("./Utility/Data/Story");
const GlobalPointBrightnessDuck_1 = require("./Ducks/GlobalPointBrightnessDuck");
const ChannelBrightnessDuck_1 = require("./Ducks/ChannelBrightnessDuck");
const GenericFingerprintAttributesDuck_1 = require("./Ducks/GenericFingerprintAttributesDuck");
const GroupVisualizationMode_1 = require("./Ducks/GroupVisualizationMode");
const HoverStateOrientationDuck_1 = require("./Ducks/HoverStateOrientationDuck");
/**
 * A TabPanel with a fixed height of 100vh which is needed for content with a scrollbar to work.
 */
function FixedHeightTabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement(Typography_1.default, Object.assign({ component: "div", role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}` }, other), React.createElement(core_1.Paper, { style: { overflow: 'hidden', height: '100vh' } }, children)));
}
const mapStateToProps = (state) => ({
    openTab: state.openTab,
    dataset: state.dataset,
    categoryOptions: state.categoryOptions,
    channelSize: state.channelSize,
    channelColor: state.channelColor,
    channelBrightness: state.channelBrightness,
    splitRef: state.splitRef,
    hoverStateOrientation: state.hoverStateOrientation
});
const mapDispatchToProps = dispatch => ({
    addStory: story => dispatch(StoriesDuck_1.addStory(story)),
    setActiveStory: (activeStory) => dispatch(StoriesDuck_1.setActiveStory(activeStory)),
    setOpenTab: openTab => dispatch(OpenTabDuck_1.setOpenTabAction(openTab)),
    setDataset: dataset => dispatch(DatasetDuck_1.setDatasetAction(dataset)),
    setAdvancedColoringSelection: value => dispatch(AdvancedColoringSelectionDuck_1.setAdvancedColoringSelectionAction(value)),
    setActiveLine: value => dispatch(ActiveLineDuck_1.setActiveLine(value)),
    setProjectionColumns: projectionColumns => dispatch(ProjectionColumnsDuck_1.setProjectionColumns(projectionColumns)),
    setProjectionOpen: projectionOpen => dispatch(ProjectionOpenDuck_1.setProjectionOpenAction(projectionOpen)),
    setWebGLView: webGLView => dispatch(WebGLViewDuck_1.setWebGLView(webGLView)),
    setClusterMode: clusterMode => dispatch(ClusterModeDuck_1.setClusterModeAction(clusterMode)),
    setPathLengthMaximum: maximum => dispatch(PathLengthRange_1.setPathLengthMaximum(maximum)),
    setPathLengthRange: range => dispatch(PathLengthRange_1.setPathLengthRange(range)),
    setCategoryOptions: categoryOptions => dispatch(CategoryOptionsDuck_1.setCategoryOptions(categoryOptions)),
    setChannelSize: channelSize => dispatch(ChannelSize_1.setChannelSize(channelSize)),
    setGlobalPointSize: size => dispatch(GlobalPointSizeDuck_1.setGlobalPointSize(size)),
    wipeState: () => dispatch({ type: 'RESET_APP' }),
    setChannelColor: channelColor => dispatch(ChannelColorDuck_1.setChannelColor(channelColor)),
    // setLineUpInput_data: input => dispatch(setLineUpInput_data(input)),
    // setLineUpInput_columns: input => dispatch(setLineUpInput_columns(input)),
    setChannelBrightness: channelBrightness => dispatch(ChannelBrightnessDuck_1.setChannelBrightnessSelection(channelBrightness)),
    setLineUpInput_visibility: input => dispatch(LineUpInputDuck_1.setLineUpInput_visibility(input)),
    saveProjection: embedding => dispatch(ProjectionsDuck_1.addProjectionAction(embedding)),
    setVectors: vectors => dispatch(StoriesDuck_1.setVectors(vectors)),
    setLineByOptions: options => dispatch(SelectedLineByDuck_1.setLineByOptions(options)),
    setSplitRef: splitRef => dispatch(SplitRefDuck_1.setSplitRef(splitRef)),
    setGlobalPointBrightness: value => dispatch(GlobalPointBrightnessDuck_1.setGlobalPointBrightness(value)),
    setGenericFingerprintAttriutes: value => dispatch(GenericFingerprintAttributesDuck_1.setGenericFingerprintAttributes(value)),
    setGroupVisualizationMode: value => dispatch(GroupVisualizationMode_1.setGroupVisualizationMode(value))
});
/**
 * Factory method which is declared here so we can get a static type in 'ConnectedProps'
 */
const connector = react_redux_2.connect(mapStateToProps, mapDispatchToProps);
/**
 * Main application that contains all other components.
 */
var Application = connector(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileDialogOpen: true
        };
        this.threeRef = React.createRef();
        this.splitRef = React.createRef();
        this.props.setWebGLView(this.threeRef);
        this.legend = React.createRef();
        this.onLineSelect = this.onLineSelect.bind(this);
        this.onDataSelected = this.onDataSelected.bind(this);
    }
    componentDidMount() {
        const mangleURL = (url) => {
            if (url.endsWith('csv') || url.endsWith('json') || url.endsWith('sdf')) {
                return `datasets/${url}`;
            }
            return `datasets/${url}.csv`;
        };
        var url = new URL(window.location.toString());
        var set = url.searchParams.get("set");
        var preselect = frontend_utils.CHEM_PROJECT ? "test.sdf" : "datasets/rubik/cube10x2_different_origins.csv";
        var loader = frontend_utils.CHEM_PROJECT ? new SDFLoader_1.SDFLoader() : new CSVLoader_1.CSVLoader();
        if (set != null) {
            if (set == "neural") {
                preselect = "datasets/neural/learning_confmat.csv";
                loader = new CSVLoader_1.CSVLoader();
            }
            else if (set == "rubik") {
                preselect = "datasets/rubik/cube10x2_different_origins.csv";
                loader = new CSVLoader_1.CSVLoader();
            }
            else if (set == "chess") {
                preselect = "datasets/chess/chess16k.csv";
                loader = new CSVLoader_1.CSVLoader();
            }
            else if (set == "cime") {
                preselect = "test.sdf";
                loader = new SDFLoader_1.SDFLoader();
            }
            else {
                preselect = mangleURL(set);
            }
            loader.resolvePath(new DatasetDatabase_1.DatasetDatabase().getByPath(preselect), (dataset, json) => { this.onDataSelected(dataset, json); });
        }
        else {
            loader.resolvePath(new DatasetDatabase_1.DatasetDatabase().getByPath(preselect), (dataset, json) => { this.onDataSelected(dataset, json); });
        }
    }
    convertRemToPixels(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
    /**
     * Main callback when the dataset changes
     * @param dataset
     * @param json
     */
    onDataSelected(dataset, json) {
        // Wipe old state
        this.props.wipeState();
        // Dispose old view
        this.threeRef.current.disposeScene();
        this.props.setClusterMode(dataset.multivariateLabels ? ClusterModeDuck_1.ClusterMode.Multivariate : ClusterModeDuck_1.ClusterMode.Univariate);
        // if(!frontend_utils.CHEM_PROJECT)
        this.props.setGroupVisualizationMode(dataset.multivariateLabels ? GroupVisualizationMode_1.GroupVisualizationMode.StarVisualization : GroupVisualizationMode_1.GroupVisualizationMode.ConvexHull);
        // Set new dataset as variable
        this.props.setDataset(dataset);
        // Load new view
        let lineScheme = this.mappingFromScale(NamedCategoricalScales_1.NamedCategoricalScales.DARK2(), { key: 'algo' }, dataset);
        this.setState({
            lineColorScheme: lineScheme
        });
        this.threeRef.current.createVisualization(dataset, lineScheme, null);
        this.finite(lineScheme, json, dataset);
        this.props.setVectors(dataset.vectors);
        // this.props.setLineUpInput_columns(dataset.columns);
        // this.props.setLineUpInput_data(dataset.vectors);
        this.props.setSplitRef(this.splitRef);
        this.props.setLineByOptions(dataset.getColumns());
        setTimeout(() => this.threeRef.current.requestRender(), 500);
        // set default storybook that contains all clusters and no arrows
        if (dataset.clusters.length > 0) {
            let story = new Story_1.Story(dataset.clusters, []);
            this.props.addStory(story);
            this.props.setActiveStory(null);
            // this.props.setActiveStory(story) // TODO: should we set the new story active?
        }
    }
    finite(lineColorScheme, categories, dataset) {
        var _a;
        var algos = LineTreePopover_1.LineSelectionTree_GenAlgos(this.props.dataset.vectors);
        var selLines = LineTreePopover_1.LineSelectionTree_GetChecks(algos);
        // Update shape legend
        this.setState({
            selectedLines: selLines,
            selectedLineAlgos: algos
        });
        this.props.setCategoryOptions(new CategoryOptions_1.CategoryOptions(this.props.dataset.vectors, categories));
        this.props.setPathLengthMaximum(dataset.getMaxPathLength());
        this.props.setPathLengthRange([0, dataset.getMaxPathLength()]);
        this.props.saveProjection(new Embedding_1.Embedding(dataset.vectors, "Initial Projection"));
        this.props.setGenericFingerprintAttriutes(dataset.getColumns(true).map(column => ({
            feature: column,
            show: dataset.columns[column].project
        })));
        const formatRange = range => {
            try {
                return `${range.min.toFixed(2)} - ${range.max.toFixed(2)}`;
            }
            catch (_a) {
                return 'unknown';
            }
        };
        this.props.setProjectionColumns(dataset.getColumns(true).map(column => ({
            name: column,
            checked: dataset.columns[column].project,
            normalized: true,
            range: dataset.columns[column].range ? formatRange(dataset.columns[column].range) : "unknown",
            featureLabel: dataset.columns[column].featureLabel
        })));
        (_a = this.legend.current) === null || _a === void 0 ? void 0 : _a.load(dataset.info.type, lineColorScheme, this.state.selectedLineAlgos);
        this.initializeEncodings(dataset);
    }
    mappingFromScale(scale, attribute, dataset) {
        if (scale instanceof ContinuosScale_1.DiscreteScale) {
            // Generate scale
            return new DiscreteMapping_1.DiscreteMapping(scale, [...new Set(this.props.dataset.vectors.map(vector => vector[attribute.key]))]);
        }
        if (scale instanceof ContinuosScale_1.ContinuosScale) {
            var min = null, max = null;
            if (dataset.columns[attribute.key].range) {
                min = dataset.columns[attribute.key].range.min;
                max = dataset.columns[attribute.key].range.max;
            }
            else {
                var filtered = this.props.dataset.vectors.map(vector => vector[attribute.key]);
                max = Math.max(...filtered);
                min = Math.min(...filtered);
            }
            return new ContinuousMapping_1.ContinuousMapping(scale, { min: min, max: max });
        }
        return null;
    }
    initializeEncodings(dataset) {
        var state = {};
        this.threeRef.current.particles.shapeCat(null);
        var defaultSizeAttribute = this.props.categoryOptions.getAttribute('size', 'multiplicity', 'sequential');
        if (defaultSizeAttribute) {
            this.props.setGlobalPointSize([1, 2]);
            this.props.setChannelSize(defaultSizeAttribute);
            this.threeRef.current.particles.sizeCat(defaultSizeAttribute, [1, 2]);
        }
        else {
            this.props.setGlobalPointSize([1]);
            this.props.setChannelSize(null);
            this.threeRef.current.particles.sizeCat(defaultSizeAttribute, [1]);
        }
        var defaultColorAttribute = this.props.categoryOptions.getAttribute("color", "algo", "categorical");
        if (defaultColorAttribute) {
            this.props.setChannelColor(defaultColorAttribute);
        }
        else {
            this.props.setChannelColor(null);
        }
        var defaultBrightnessAttribute = this.props.categoryOptions.getAttribute("transparency", "age", "sequential");
        if (defaultBrightnessAttribute) {
            this.props.setGlobalPointBrightness([0.25, 1]);
            this.props.setChannelBrightness(defaultBrightnessAttribute);
            this.threeRef.current.particles.transparencyCat(defaultBrightnessAttribute, [0.25, 1]);
        }
        else {
            this.props.setGlobalPointBrightness([1]);
            this.props.setChannelBrightness(null);
            this.threeRef.current.particles.transparencyCat(defaultBrightnessAttribute, [1]);
        }
        this.setState(state);
    }
    onLineSelect(algo, show) {
        this.threeRef.current.filterLines(algo, show);
        this.threeRef.current.requestRender();
    }
    onChangeTab(newTab) {
        if (newTab === this.props.openTab) {
            this.props.setOpenTab(false);
        }
        else {
            this.props.setOpenTab(newTab);
        }
    }
    render() {
        return React.createElement("div", null,
            React.createElement("div", { style: {
                    display: 'flex',
                    alignItems: 'stretch',
                    width: "100vw",
                    height: "100vh"
                } },
                React.createElement(core_1.Drawer, { variant: "permanent", style: {
                        width: 88
                    } },
                    React.createElement(core_1.Divider, null),
                    React.createElement(Tabs_1.default, { value: this.props.openTab, orientation: "vertical", indicatorColor: "primary", textColor: "primary", onChange: (e, newTab) => this.onChangeTab(newTab), "aria-label": "disabled tabs example" },
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "Load Dataset"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Upload a new dataset or choose a predefined one.")) },
                            React.createElement(Tab_1.default, { value: 0, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_dataset_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } })),
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "Embedding and Projection"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Perform projection techniques like t-SNE, UMAP, or a force-directly layout with your data.")) },
                            React.createElement(Tab_1.default, { className: "pse-tab", value: 1, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_project_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } })),
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "Point and Line Channels"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Contains settings that let you map different channels like brightness and color on point and line attributes.")) },
                            React.createElement(Tab_1.default, { className: "pse-tab", value: 2, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_encoding_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } })),
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "Groups"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Contains options for displaying and navigating groups in the dataset.")) },
                            React.createElement(Tab_1.default, { className: "pse-tab", value: 3, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_clusters_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } })),
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "Hover Item and Selection Summary"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Contains information about the currently hovered item and the currently selected summary.")) },
                            React.createElement(Tab_1.default, { className: "pse-tab", value: 4, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_details_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } })),
                        React.createElement(core_1.Tooltip, { placement: "right", title: React.createElement(React.Fragment, null,
                                React.createElement(Typography_1.default, { variant: "subtitle2" }, "LineUp Integration"),
                                React.createElement(Typography_1.default, { variant: "body2" }, "Settings for LineUp Integration")) },
                            React.createElement(Tab_1.default, { className: "pse-tab", value: 5, icon: React.createElement(core_1.SvgIcon, { style: { fontSize: 64 }, viewBox: "0 0 18.521 18.521", component: pse_icon_lineup_svg_1.default }), style: { minWidth: 0, flexGrow: 1, paddingTop: 16, paddingBottom: 16 } })))),
                React.createElement(Box_1.default, { style: {
                        flexShrink: 0,
                        width: this.props.openTab === false ? '0rem' : "18rem",
                        height: '100vh',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid rgba(0, 0, 0, 0.12)'
                    } },
                    React.createElement("div", { style: {
                            flexGrow: 1,
                            overflowY: 'hidden'
                        } },
                        React.createElement(Grid_1.default, { container: true, justify: "center", alignItems: "stretch", direction: "column" },
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 0 },
                                React.createElement(DatasetTabPanel_1.DatasetTabPanel, { onDataSelected: this.onDataSelected })),
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 1 },
                                React.createElement(EmbeddingTabPanel_1.EmbeddingTabPanel, null)),
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 2 },
                                React.createElement(StatesTabPanel_1.StatesTabPanel, { lineColorScheme: this.state.lineColorScheme })),
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 3 }, this.props.dataset != null ?
                                React.createElement(ClusteringTabPanel_1.ClusteringTabPanel, { open: this.props.openTab == 2, clusteringWorker: this.state.clusteringWorker }) : React.createElement("div", null)),
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 4 },
                                React.createElement(DetailsTabPanel_1.DetailsTabPanel, { hoverUpdate: (hover_item, updater) => { this.threeRef.current.hoverUpdate(hover_item, updater); } })),
                            React.createElement(FixedHeightTabPanel, { value: this.props.openTab, index: 5 },
                                React.createElement(LineUpTabPanel_1.LineUpTabPanel, null))))),
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        flexGrow: 1
                    } },
                    React.createElement(core_1.AppBar, { variant: "outlined", position: "relative", color: "transparent" },
                        React.createElement(core_1.Toolbar, null,
                            React.createElement("a", { href: "https://jku-vds-lab.at", target: "_blank" },
                                React.createElement("img", { style: { height: 48 }, src: "textures/vds-lab-logo-notext.svg" })),
                            frontend_utils.CHEM_PROJECT && React.createElement("a", { href: "https://www.bayer.com", target: "_blank" },
                                React.createElement("img", { style: { height: 48, marginLeft: 48 }, src: "textures/bayer-logo.svg", alt: "Powered By Bayer" })),
                            React.createElement(Typography_1.default, { variant: "h6", style: { marginLeft: 48, color: "rgba(0, 0, 0, 0.54)" } }, frontend_utils.CHEM_PROJECT ? "CIME: Chem-Informatics Model Explorer" : "Projection Space Explorer"))),
                    React.createElement(react_split_1.default, { style: { display: 'flex', flexDirection: 'column', height: '100%' }, ref: this.splitRef, sizes: [100, 0], minSize: 0, expandToMin: false, gutterSize: 10, gutterAlign: "center", snapOffset: 30, dragInterval: 1, direction: "vertical", cursor: "ns-resize", onDragStart: () => {
                            this.props.setLineUpInput_visibility(false);
                        }, onDragEnd: (sizes) => {
                            if (sizes[0] > 90) {
                                this.props.setLineUpInput_visibility(false);
                            }
                            else {
                                this.props.setLineUpInput_visibility(true);
                            }
                        } },
                        React.createElement("div", { style: { flexGrow: 0.9 } },
                            React.createElement(WebGLView_1.WebGLView, { ref: this.threeRef })),
                        React.createElement("div", { style: { flexGrow: 0.1 } },
                            React.createElement(LineUpContext_1.LineUpContext, { onFilter: () => { this.threeRef.current.lineupFilterUpdate(); }, hoverUpdate: (hover_item, updater) => { this.threeRef.current.hoverUpdate(hover_item, updater); } })))),
                React.createElement(StateSequenceDrawer_1.StateSequenceDrawerRedux, null),
                React.createElement(Storytelling_1.Storytelling, null),
                React.createElement(StoryEditor_1.StoryEditor, null),
                this.props.hoverStateOrientation == HoverStateOrientationDuck_1.HoverStateOrientation.SouthWest && React.createElement("div", { id: "HoverItemDiv", style: {
                        position: 'absolute',
                        left: '0px',
                        bottom: '0px',
                        zIndex: 10000,
                        padding: 8
                    } }),
                this.props.hoverStateOrientation == HoverStateOrientationDuck_1.HoverStateOrientation.NorthEast && React.createElement("div", { id: "HoverItemDiv", style: {
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                        zIndex: 10000,
                        padding: 8
                    } })));
    }
});
const theme = core_1.createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#007dad',
        }
    }
});
// Render the application into our 'mountingPoint' div that is declared in 'index.html'.
ReactDOM.render(React.createElement(react_redux_1.Provider, { store: redux_1.createStore(Store_1.rootReducer, redux_devtools_extension_1.devToolsEnhancer({})) },
    React.createElement(core_1.MuiThemeProvider, { theme: theme },
        React.createElement(Application, null))), document.getElementById("mountingPoint"));
