"use strict";
/**
 * Directed graph library for javascript.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.graphLayout = exports.Edge = exports.Node = exports.Graph = void 0;
/**
 * Graph class which holds the nodes and edges of the graph.
 */
class Graph {
    constructor(nodes, edges) {
        this.nodes = nodes;
        this.edges = edges;
    }
}
exports.Graph = Graph;
/**
 * Node class holding the vectors that are in this node.
 */
class Node {
    constructor(vectors) {
        this.vectors = vectors;
    }
}
exports.Node = Node;
/**
 * Edge class that is a connection between 2 nodes.
 */
class Edge {
    constructor(source, destination, bundle) {
        this.source = source;
        this.destination = destination;
        this.bundle = bundle;
    }
}
exports.Edge = Edge;
/**
 * Performs the graphing algorithm.
 * @param {*} clusters
 * @param {*} vectors
 */
function graphLayout(clusters) {
    var edges = [];
    // For each cluster,
    Object.keys(clusters).forEach(srcKey => {
        var srcCluster = clusters[srcKey];
        Object.keys(clusters).forEach(dstKey => {
            var dstCluster = clusters[dstKey];
            if (dstCluster != srcCluster) {
                var bundle = [];
                // For each vector in source cluster, check if the direct ancestor is in the destination cluster
                srcCluster.vectors.forEach(srcVec => {
                    if (dstCluster.vectors.find(dstVec => srcVec.view.segment.lineKey == dstVec.view.segment.lineKey && srcVec.view.sequenceIndex + 1 == dstVec.view.sequenceIndex)) {
                        bundle.push(srcVec.view.segment.lineKey);
                    }
                });
                if (bundle.length > 10) {
                    var edge = new Edge(srcCluster, dstCluster, [...new Set(bundle)]);
                    edges.push(edge);
                }
            }
        });
    });
    return [edges];
}
exports.graphLayout = graphLayout;
