"use strict";
/**
 * Duck file for the RDKit Settings
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRDKit_doAlignment = exports.setRDKit_width = exports.setRDKit_showMCS = exports.setRDKit_refresh = exports.setRDKit_sigma = exports.setRDKit_scale = exports.setRDKit_contourLines = void 0;
const SET_CONTOURLINES = "ducks/rdkitsettings/SET_CONTOURLINES";
const SET_SCALE = "ducks/rdkitsettings/SET_SCALE";
const SET_SIGMA = "ducks/rdkitsettings/SET_SIGMA";
const SET_REFRESH = "ducks/rdkitsettings/SET_REFRESH";
const SET_SHOW_MCS = "ducks/rdkitsettings/SET_SHOW_MCS";
const SET_WIDTH = "ducks/rdkitsettings/SET_WIDTH";
const SET_DO_ALIGNMENT = "ducks/rdkitsettings/SET_DO_ALIGNMENT";
exports.setRDKit_contourLines = input => ({
    type: SET_CONTOURLINES,
    input: input
});
exports.setRDKit_scale = input => ({
    type: SET_SCALE,
    input: input
});
exports.setRDKit_sigma = input => ({
    type: SET_SIGMA,
    input: input
});
exports.setRDKit_refresh = input => ({
    type: SET_REFRESH,
    input: input
});
exports.setRDKit_showMCS = input => ({
    type: SET_SHOW_MCS,
    input: input
});
exports.setRDKit_width = input => ({
    type: SET_WIDTH,
    input: input
});
exports.setRDKit_doAlignment = input => ({
    type: SET_DO_ALIGNMENT,
    input: input
});
const initialState = {
    contourLines: 10,
    scale: -1,
    sigma: 0,
    refresh: 0,
    showMCS: true,
    width: 250,
    doAlignment: true,
};
const rdkitSettings = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTOURLINES:
            return Object.assign(Object.assign({}, state), { contourLines: action.input });
        case SET_SCALE:
            return Object.assign(Object.assign({}, state), { scale: action.input });
        case SET_SIGMA:
            return Object.assign(Object.assign({}, state), { sigma: action.input });
        case SET_REFRESH:
            return Object.assign(Object.assign({}, state), { refresh: action.input });
        case SET_SHOW_MCS:
            return Object.assign(Object.assign({}, state), { showMCS: action.input });
        case SET_WIDTH:
            return Object.assign(Object.assign({}, state), { width: action.input });
        case SET_DO_ALIGNMENT:
            return Object.assign(Object.assign({}, state), { doAlignment: action.input });
        default:
            return state;
    }
};
exports.default = rdkitSettings;
