"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscreteScale = exports.ContinuosScale = void 0;
const SchemeColor_1 = require("./SchemeColor");
const LinearColorScale_1 = require("./LinearColorScale");
var d3v5 = require('d3');
class ContinuosScale extends LinearColorScale_1.LinearColorScale {
    constructor(stops) {
        super(stops, "continuous");
    }
    map(value) {
        var d3color = d3v5.color(this.interpolator(value));
        return SchemeColor_1.SchemeColor.rgbToHex(d3color.r, d3color.g, d3color.b);
    }
}
exports.ContinuosScale = ContinuosScale;
class DiscreteScale extends LinearColorScale_1.LinearColorScale {
    constructor(stops) {
        super(stops, "discrete");
    }
    map(value) {
        return this.stops[value % this.stops.length];
    }
}
exports.DiscreteScale = DiscreteScale;
