"use strict";
/**
 * Duck file for the LineUp input data
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLineUpInput_update = exports.setLineUpInput_lineup = exports.updateLineUpInput_filter = exports.setLineUpInput_filter = exports.setLineUpInput_dump = exports.setLineUpInput_visibility = void 0;
const lodash_1 = require("lodash");
// const SET_DATA = "ducks/lineUpInput/SET_DATA"
// const SET_COLUMNS = "ducks/lineUpInput/SET_COLUMNS"
const SET_VISIBILITY = "ducks/lineUpInput/SET_VISIBILITY";
const SET_DUMP = "ducks/lineUpInput/SET_DUMP";
const SET_FILTER = "ducks/lineUpInput/SET_FILTER";
const UPDATE_FILTER = "ducks/lineUpInput/UPDATE_FILTER";
const SET_LINEUP = "ducks/lineUpInput/SET_LINEUP";
const SET_UPDATE = "ducks/lineUpInput/SET_UPDATE";
// export const setLineUpInput_data = input => ({
//     type: SET_DATA,
//     input: input
// });
// export const setLineUpInput_columns = input => ({
//     type: SET_COLUMNS,
//     input: input
// });
exports.setLineUpInput_visibility = input => ({
    type: SET_VISIBILITY,
    input: input
});
exports.setLineUpInput_dump = input => ({
    type: SET_DUMP,
    input: input
});
exports.setLineUpInput_filter = input => ({
    type: SET_FILTER,
    input: input
});
exports.updateLineUpInput_filter = input => ({
    type: UPDATE_FILTER,
    input: input
});
exports.setLineUpInput_lineup = input => ({
    type: SET_LINEUP,
    input: input
});
exports.setLineUpInput_update = input => ({
    type: SET_UPDATE,
    input: input
});
const initialState = {
    // data: null,
    // columns: null,
    show: false,
    dump: "",
    filter: null,
    previousfilter: null,
    lineup: null,
    update: 0,
};
const lineUpInput = (state = initialState, action) => {
    switch (action.type) {
        // case SET_DATA:
        //     return {...state, data: action.input}
        // case SET_COLUMNS:
        //     return {...state, columns: action.input}
        case SET_VISIBILITY:
            return Object.assign(Object.assign({}, state), { show: action.input });
        case SET_DUMP:
            return Object.assign(Object.assign({}, state), { dump: action.input });
        case SET_FILTER:
            const prev_filter = Object.assign({}, state.filter);
            return Object.assign(Object.assign({}, state), { previousfilter: prev_filter, filter: action.input });
        case UPDATE_FILTER:
            if (state.filter && Object.keys(state.filter).includes(action.input["key"])) {
                if (state.filter[action.input["key"]] == action.input["val_old"]) {
                    const filter_new = Object.assign({}, lodash_1.filter);
                    filter_new[action.input["key"]] = action.input["val_new"];
                    return Object.assign(Object.assign({}, state), { filter: filter_new });
                }
            }
            return state;
        case SET_LINEUP:
            return Object.assign(Object.assign({}, state), { lineup: action.input });
        case SET_UPDATE:
            const cur = state.update;
            return Object.assign(Object.assign({}, state), { update: cur + 1 });
        default:
            return state;
    }
};
exports.default = lineUpInput;
