"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSplitRef = void 0;
const SET = "ducks/splitRef/SET";
exports.setSplitRef = splitRef => ({
    type: SET,
    splitRef: splitRef
});
const initialState = null;
function splitRef(state = initialState, action) {
    switch (action.type) {
        case SET:
            return action.splitRef;
        default:
            return state;
    }
}
exports.default = splitRef;
