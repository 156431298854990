"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShapeLegend = void 0;
const React = require("react");
const Checkbox_1 = require("@material-ui/core/Checkbox");
const clone = require("clone");
require("./ShapeLegend.scss");
const ShapeSymbol = ({ symbol, text, checked, onCheck }) => {
    var paths = {
        "star": "./textures/sprites/star.png",
        "circle": "./textures/sprites/circle.png",
        "square": "./textures/sprites/square.png",
        "cross": "./textures/sprites/cross.png"
    };
    return React.createElement("div", { key: symbol },
        React.createElement(Checkbox_1.default, { className: "ShapeLegendCheckbox", checked: checked, color: "primary", onChange: (event) => { onCheck(event, symbol); }, disableRipple: true, inputProps: { 'aria-label': 'decorative checkbox' } }),
        React.createElement("img", { src: paths[symbol], style: {
                width: "1rem",
                height: "1rem",
                verticalAlign: "middle"
            } }),
        React.createElement("span", { style: {
                verticalAlign: "middle",
                marginLeft: "0.5rem"
            } }, text));
};
function defaultState() {
    return {
        cross: true, circle: true, star: true, square: true
    };
}
/**
 * Simple shape legend
 * put a category in props
 */
exports.ShapeLegend = ({ dataset, category, onChange }) => {
    const [state, setState] = React.useState(defaultState());
    React.useEffect(() => {
        setState(defaultState());
    }, [category, dataset]);
    var defaults = [{ symbol: 'cross', text: 'First State' }, { symbol: 'circle', text: 'Intermediate State' }, { symbol: 'star', text: 'Last State' }];
    if (category == null) {
        return React.createElement("div", null, defaults.map(def => {
            return React.createElement(ShapeSymbol, { key: def.symbol, symbol: def.symbol, text: def.text, checked: state[def.symbol], onCheck: (event) => {
                    var newState = clone(state);
                    newState[def.symbol] = event.target.checked;
                    setState(newState);
                    onChange(newState);
                } });
        }));
    }
    return React.createElement("div", null, category.values.map(v => {
        return React.createElement(ShapeSymbol, { key: v.from, symbol: v.to, text: "display" in v ? v.display : v.from, checked: state[v.to], onCheck: (event) => {
                var newState = clone(state);
                newState[v.to] = event.target.checked;
                setState(newState);
                onChange(newState);
            } });
    }));
};
