"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineUpTabPanel = void 0;
const core_1 = require("@material-ui/core");
const core_2 = require("@material-ui/core");
const React = require("react");
const react_redux_1 = require("react-redux");
const LineUpInputDuck_1 = require("../../Ducks/LineUpInputDuck");
const GetApp_1 = require("@material-ui/icons/GetApp");
const mapStateToProps = (state) => ({
    dataset: state.dataset,
    currentAggregation: state.currentAggregation,
    lineUpInput: state.lineUpInput,
    splitRef: state.splitRef,
});
const mapDispatchToProps = dispatch => ({
    setLineUpInput_visibility: value => dispatch(LineUpInputDuck_1.setLineUpInput_visibility(value)),
    setLineUpInput_filter: value => dispatch(LineUpInputDuck_1.setLineUpInput_filter(value)),
});
const connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.LineUpTabPanel = connector(({ setLineUpInput_visibility, setLineUpInput_filter, lineUpInput, dataset, currentAggregation, splitRef }) => {
    const handleChange = (_, value) => {
    };
    const onLoad = (filter) => {
        setLineUpInput_visibility(true);
        setLineUpInput_filter(filter);
        const curr_sizes = splitRef.current.split.getSizes();
        if (curr_sizes[1] < 2) {
            splitRef.current.split.setSizes([curr_sizes[0], 70]);
        }
    };
    // https://stackoverflow.com/questions/31214677/download-a-reactjs-object-as-a-file
    const downloadImpl = (data, name, mimetype) => {
        var b = new Blob([data], { type: mimetype });
        var csvURL = window.URL.createObjectURL(b);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', name);
        tempLink.click();
    };
    const exportCSV = () => {
        if (lineUpInput.lineup && lineUpInput.lineup.data) {
            // exports all data that is currently shown in the table -> filters and sorts are applied! also annotations are included
            lineUpInput.lineup.data.exportTable(lineUpInput.lineup.data.getRankings()[0], { separator: "," }).then(x => downloadImpl(x, `lineup-export.csv`, 'application/csv'));
        }
    };
    let [cell_value_vis, set_cell_value_vis] = React.useState(false);
    React.useEffect(() => {
        let style = document.getElementById('cell_value_vis');
        if (!style) {
            style = document.createElement('style');
            style.setAttribute("id", "cell_value_vis");
            style.setAttribute("type", "text/css");
            const head = document.head || document.getElementsByTagName('head')[0];
            head.appendChild(style);
        }
        const css = cell_value_vis ? '.lu-hover-only { visibility: visible; }' : '.lu-hover-only { visibility: hidden; }';
        // @ts-ignore
        if (style.styleSheet) {
            // This is required for IE8 and below.
            // @ts-ignore
            style.styleSheet.cssText = css;
        }
        else {
            style.innerHTML = "";
            style.appendChild(document.createTextNode(css));
        }
    }, [cell_value_vis]);
    const toggleVis = () => {
        set_cell_value_vis(() => { return !cell_value_vis; });
    };
    return React.createElement("div", { style: { display: 'flex', flexDirection: 'column', height: '100%' } },
        React.createElement(core_2.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
            React.createElement(core_1.Typography, { variant: "subtitle2", gutterBottom: true }, "LineUp Settings")),
        React.createElement(core_2.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
            React.createElement(core_2.Button, { fullWidth: true, style: { marginRight: 2 }, variant: "outlined", onClick: () => onLoad({ 'reset': true }) }, "Load All")),
        React.createElement(core_2.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
            React.createElement(core_2.Button, { fullWidth: true, variant: "outlined", onClick: () => onLoad({ 'selection': currentAggregation.aggregation }) }, "Load Selection")),
        React.createElement(core_2.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
            React.createElement(core_2.FormControlLabel, { control: React.createElement(core_2.Switch, { color: "primary", value: cell_value_vis, onChange: (event) => {
                        toggleVis();
                    } }), label: "Show Cell Values" })),
        React.createElement(core_2.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
            React.createElement(core_2.Button, { fullWidth: true, variant: "outlined", onClick: () => { exportCSV(); } },
                React.createElement(GetApp_1.default, null),
                "\u00A0Export CSV")));
});
