"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChemLegendParent = void 0;
const React = require("react");
require("./chem.scss");
const backend_utils = require("../../../utils/backend-connect");
const core_1 = require("@material-ui/core");
const react_promise_tracker_1 = require("react-promise-tracker");
const LoadingIndicator_1 = require("../../Utility/Loaders/LoadingIndicator");
const react_redux_1 = require("react-redux");
const Refresh_1 = require("@material-ui/icons/Refresh");
const Settings_1 = require("@material-ui/icons/Settings");
const Info_1 = require("@material-ui/icons/Info");
const promise_helpers_1 = require("../../../utils/promise-helpers");
const FilterList_1 = require("@material-ui/icons/FilterList");
const AggregationDuck_1 = require("../../Ducks/AggregationDuck");
const lab_1 = require("@material-ui/lab");
const lodash_1 = require("lodash");
const RDKitSettingsDuck_1 = require("../../Ducks/RDKitSettingsDuck");
const AddCircleOutline_1 = require("@material-ui/icons/AddCircleOutline");
const HoverSettingsDuck_1 = require("../../Ducks/HoverSettingsDuck");
const Delete_1 = require("@material-ui/icons/Delete");
/**
 * Chem Legend, implemented
 */
const mapStateToProps_Chem = (state) => {
    var _a;
    return ({
        dataset: state.dataset,
        hoverSettings: state.hoverSettings,
        rdkitSettings: state.rdkitSettings,
        columns: (_a = state.dataset) === null || _a === void 0 ? void 0 : _a.columns,
    });
};
const mapDispatchToProps_Chem = dispatch => ({
    setCurrentAggregation: samples => dispatch(AggregationDuck_1.setAggregationAction(samples))
});
const connector_Chem = react_redux_1.connect(mapStateToProps_Chem, mapDispatchToProps_Chem);
exports.ChemLegendParent = connector_Chem(function (props) {
    const { cancellablePromise, cancelPromises } = promise_helpers_1.default();
    if (props.mcs_only) {
        const [mcsComp, setMcsComp] = React.useState(React.createElement("div", null, "loading..."));
        let smiles_col = "SMILES";
        React.useEffect(() => {
            cancelPromises();
            if (smiles_col in props.columns) {
                const controller = new AbortController();
                let my_fetch = null;
                if (props.diff && props.selection_ref) {
                    const smilesA = props.selection.map(row => row[smiles_col]);
                    const smilesB = props.selection_ref.map(row => row[smiles_col]);
                    my_fetch = backend_utils.get_difference_highlight(smilesA, smilesB, controller);
                }
                else {
                    const formData = new FormData();
                    props.selection.every((row) => {
                        formData.append('smiles_list', row[smiles_col]);
                        return true;
                    });
                    my_fetch = backend_utils.get_mcs_from_smiles_list(formData, controller);
                }
                react_promise_tracker_1.trackPromise(cancellablePromise(my_fetch
                    .then(x => {
                    if (x.length > 100) { // check if it is actually long enogh to be an img
                        setMcsComp(() => React.createElement("div", { style: { width: 200, height: 200, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundImage: `url('data:image/jpg;base64,${x}')` } }));
                    }
                    else {
                        setMcsComp(() => React.createElement("div", null, x));
                    }
                }), controller));
            }
        }, [props.selection, props.selection_ref, props.mcs_only]);
        if (smiles_col in props.columns) {
            return React.createElement("div", null, mcsComp);
        }
        return React.createElement("div", null, "No SMILES column found.");
    }
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [repList, setRepList] = React.useState(["Common Substructure"]);
    const [chemComponents, setChemComponents] = React.useState([0]);
    const [chemComponentsCurrentRep, setChemComponentsCurrentRep] = React.useState(["Common Substructure"]);
    const loadRepList = function (refresh = false) {
        if (refresh || repList.length <= 1) {
            const controller = new AbortController();
            cancellablePromise(backend_utils.get_representation_list(refresh, props.dataset.info.path, controller)
                .then(x => {
                if (x["rep_list"].length > 0) {
                    let rep_list = [...x["rep_list"]];
                    rep_list.splice(0, 0, "Common Substructure");
                    setRepList(rep_list);
                }
            }), controller);
        }
    };
    const addComp = function () {
        let comps = [...chemComponents];
        comps.push(Math.max(...comps) + 1);
        setChemComponents(comps);
        let compsCR = [...chemComponentsCurrentRep];
        compsCR.push("Common Substructure");
        setChemComponentsCurrentRep(compsCR);
    };
    React.useEffect(() => {
        cancelPromises();
        loadRepList();
    }, []);
    const removeComponent = (id) => {
        let comps = [...chemComponents];
        let compsCR = [...chemComponentsCurrentRep];
        const index = comps.indexOf(id);
        if (index > -1) {
            comps.splice(index, 1);
            compsCR.splice(index, 1);
        }
        setChemComponents(comps);
        setChemComponentsCurrentRep(compsCR);
    };
    const setCurrentRep = (value, id) => {
        if (repList.includes(value)) {
            let compsCR = [...chemComponentsCurrentRep];
            const index = chemComponents.indexOf(id);
            compsCR[index] = value;
            setChemComponentsCurrentRep(compsCR);
        }
    };
    const anchorRef = React.useRef();
    const chemRef = React.useRef();
    if (props.aggregate) {
        return React.createElement(core_1.Box, { className: "ParentChem", paddingBottom: 3 },
            props.aggregate && React.createElement(core_1.Box, { paddingLeft: 2, paddingRight: 2 },
                React.createElement(core_1.Tooltip, { title: "Summary Settings" },
                    React.createElement(core_1.Button, { style: { color: "gray" }, ref: anchorRef, onClick: () => setSettingsOpen(true) },
                        React.createElement(Settings_1.default, null),
                        "\u00A0 Settings")),
                React.createElement(SettingsPopover, { open: settingsOpen, setOpen: setSettingsOpen, anchorEl: anchorRef.current, refreshRepList: () => { loadRepList(true); } }),
                React.createElement(core_1.Tooltip, { title: "Add Component" },
                    React.createElement(core_1.Button, { style: { color: "gray" }, onClick: () => addComp() },
                        React.createElement(AddCircleOutline_1.default, null),
                        "\u00A0 Add View"))),
            React.createElement("div", { ref: chemRef, className: "chemComponents" },
                chemComponents.length > 1 &&
                    React.createElement("div", { style: { width: (props.rdkitSettings.width + 20) * chemComponents.length } }, chemComponents.map((x, i) => {
                        return React.createElement("div", { key: x, style: { width: (props.rdkitSettings.width + 20), float: 'left' } },
                            React.createElement(ChemLegend, { chemRef: chemRef, setCurrentRep: (value) => setCurrentRep(value, x), currentRep: chemComponentsCurrentRep[i], removeComponent: () => removeComponent(x), id: x, rep_list: repList, selection: props.selection, aggregate: props.aggregate, hoverUpdate: props.hoverUpdate }));
                    })),
                chemComponents.length <= 1 &&
                    React.createElement("div", null,
                        React.createElement("div", { style: { minWidth: props.rdkitSettings.width }, key: chemComponents[0] },
                            React.createElement(ChemLegend, { chemRef: chemRef, setCurrentRep: (value) => setCurrentRep(value, chemComponents[0]), currentRep: chemComponentsCurrentRep[0], id: chemComponents[0], rep_list: repList, selection: props.selection, aggregate: props.aggregate, hoverUpdate: props.hoverUpdate })))));
    }
    else {
        return React.createElement(ChemLegend, { id: -1, rep_list: repList, selection: props.selection, aggregate: props.aggregate, hoverUpdate: props.hoverUpdate });
    }
});
const loading_area = "chemlegend_loading_area";
const UPDATER = "chemdetail";
const ChemLegend = connector_Chem(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: [],
        };
    }
    render() {
        const handleMouseEnter = (i) => {
            let hover_item = null;
            if (i >= 0) {
                hover_item = this.props.selection[i];
            }
            this.props.hoverUpdate(hover_item, UPDATER);
        };
        const handleMouseOut = () => {
            let hover_item = null;
            this.props.hoverUpdate(hover_item, UPDATER);
        };
        const setCheckedList = (value) => {
            const set_val = lodash_1.isFunction(value) ? value(this.state.checkedList) : value;
            this.setState(Object.assign(Object.assign({}, this.state), { checkedList: set_val }));
        };
        const handle_filter = () => {
            const filter_instances = this.props.selection.filter((x, i) => this.state.checkedList[i]);
            if (filter_instances.length > 0) {
                setCheckedList([]);
                this.props.setCurrentAggregation(filter_instances);
            }
            else {
                alert("Please, select at least one Compound in the Summary View to filter.");
            }
        };
        if (this.props.aggregate) {
            return React.createElement("div", { className: "ParentImg" },
                React.createElement(core_1.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
                    React.createElement(RepresentationList, { value: this.props.currentRep, onChange: this.props.setCurrentRep, rep_list: this.props.rep_list, hoverSettings: this.props.hoverSettings })),
                React.createElement(core_1.Box, { paddingLeft: 2, paddingTop: 1, paddingRight: 2 },
                    React.createElement(core_1.Button, { size: "small", variant: "outlined", onClick: () => { handle_filter(); } },
                        React.createElement(FilterList_1.default, { fontSize: "small" }),
                        "\u00A0Confirm Selection"),
                    this.props.removeComponent && React.createElement(core_1.IconButton, { onClick: this.props.removeComponent },
                        React.createElement(Delete_1.default, null))),
                React.createElement(LoadingIndicator_1.LoadingIndicatorView, { area: loading_area + this.props.id }),
                React.createElement(ImageView, { chemRef: this.props.chemRef, id: this.props.id, setCheckedList: setCheckedList, selection: this.props.selection, columns: this.props.columns, aggregate: this.props.aggregate, current_rep: this.props.currentRep, handleMouseEnter: handleMouseEnter, handleMouseOut: handleMouseOut }));
        }
        return React.createElement("div", null,
            React.createElement(ImageView, { id: this.props.id, selection: this.props.selection, columns: this.props.columns, aggregate: this.props.aggregate }));
    }
});
function loadImage(props, setComp, handleMouseEnter, handleMouseOut, cancellablePromise, setCheckedList) {
    let smiles_col = "SMILES";
    const onUpdateItem = (i, val) => {
        setCheckedList((checkedList) => {
            const list = checkedList.map((item, j) => {
                if (j === i) {
                    return val;
                }
                else {
                    return item;
                }
            });
            return list;
        });
    };
    // TODO: find by meta_data -> how to handle multiple smiles columns?
    // for (const col_name in props.columns) {
    //     let col = props.columns[col_name];
    //     if(col.metaInformation.imgSmiles)
    //         smiles_col = col_name;
    // }
    if (smiles_col in props.columns) {
        setComp(React.createElement("div", null));
        if (props.selection.length > 0) {
            if (props.aggregate) {
                const formData = new FormData();
                formData.append('current_rep', props.current_rep);
                props.selection.forEach(row => {
                    formData.append('smiles_list', row[smiles_col]);
                });
                formData.append('contourLines', props.rdkitSettings.contourLines);
                formData.append('scale', props.rdkitSettings.scale);
                formData.append('sigma', props.rdkitSettings.sigma);
                formData.append('showMCS', props.rdkitSettings.showMCS);
                formData.append('width', props.rdkitSettings.width);
                formData.append('doAlignment', props.rdkitSettings.doAlignment);
                const controller = new AbortController();
                react_promise_tracker_1.trackPromise(cancellablePromise(backend_utils.get_structures_from_smiles_list(formData, controller), controller).then(x => {
                    // @ts-ignore
                    //const img_lst = x["img_lst"].map((svg,i) => svg)
                    const img_lst = x["img_lst"].map((base64, i) => {
                        setCheckedList((checkedList) => {
                            let cpy_checked_list = [...checkedList];
                            if (cpy_checked_list.length <= i) {
                                cpy_checked_list.push(false);
                            }
                            return cpy_checked_list;
                        });
                        return React.createElement(core_1.Grid, { className: "legend_multiple", key: i, item: true },
                            React.createElement(core_1.FormControlLabel, { labelPlacement: "bottom", control: React.createElement(core_1.Checkbox, { color: "primary", onChange: (event) => { onUpdateItem(i, event.target.checked); } }), label: React.createElement("img", { src: "data:image/jpeg;base64," + base64, onMouseEnter: () => { handleMouseEnter(i); }, onMouseOver: () => { handleMouseEnter(i); }, onMouseLeave: () => { handleMouseOut(); } }) }),
                            React.createElement(core_1.Typography, { style: { paddingLeft: 5 }, variant: "subtitle2" },
                                "ID: ",
                                props.selection[i]["ID"]));
                    }); //key={props.selection[i][smiles_col]} --> gives error because sometimes smiles ocure twice
                    //<div dangerouslySetInnerHTML={{ __html: img_lst.join("") }} />
                    setComp(img_lst);
                }), loading_area + props.id);
            }
            else {
                let row = props.selection[0];
                const controller = new AbortController();
                cancellablePromise(backend_utils.get_structure_from_smiles(row[smiles_col], false, controller), controller).then(x => {
                    setComp(React.createElement("div", null,
                        React.createElement("img", { className: "legend_single", src: "data:image/jpeg;base64," + x }),
                        React.createElement(core_1.Typography, { style: { paddingLeft: 5 }, variant: "subtitle2" },
                            "ID: ",
                            row["ID"])));
                }).catch(error => console.log(error));
            }
        }
        else {
            setComp(React.createElement("div", null, "No Selection"));
        }
    }
    else {
        setComp(React.createElement("div", null, "No SMILES column found"));
    }
}
function updateImage(props, cancellablePromise) {
    let smiles_col = "SMILES";
    // TODO: find by meta_data -> how to handle multiple smiles columns?
    if (smiles_col in props.columns) {
        let imgList = props.imgContainer.childNodes;
        if (props.selection.length == imgList.length) {
            const formData = new FormData();
            formData.append('current_rep', props.current_rep);
            props.selection.forEach(row => {
                formData.append('smiles_list', row[smiles_col]);
            });
            formData.append('contourLines', props.rdkitSettings.contourLines);
            formData.append('scale', props.rdkitSettings.scale);
            formData.append('sigma', props.rdkitSettings.sigma);
            formData.append('showMCS', props.rdkitSettings.showMCS);
            formData.append('width', props.rdkitSettings.width);
            formData.append('doAlignment', props.rdkitSettings.doAlignment);
            const controller = new AbortController();
            react_promise_tracker_1.trackPromise(cancellablePromise(backend_utils.get_structures_from_smiles_list(formData, controller), controller).then(x => {
                x["img_lst"].map((base64, i) => {
                    const cur_img = imgList[i].getElementsByTagName("img")[0];
                    cur_img.src = "data:image/jpeg;base64," + base64;
                    // cur_img.width = props.rdkitSettings.width;
                    // cur_img.height = props.rdkitSettings.width;
                });
            }), loading_area + props.id);
        }
    }
}
const mapStateToProps_Img = (state) => ({
    hoverState: state.hoverState,
    rdkitSettings: state.rdkitSettings
});
const mapDispatchToProps_Img = dispatch => ({});
const connector_Img = react_redux_1.connect(mapStateToProps_Img, mapDispatchToProps_Img);
function addHighlight(element) {
    if (element && element.style) {
        element.style["border"] = "solid black 1px";
    }
}
function removeHighlight(element) {
    if (element && element.style) {
        element.style["border"] = "solid white 1px";
    }
}
const ImageView = connector_Img(function ({ chemRef, id, hoverState, selection, columns, aggregate, handleMouseEnter, handleMouseOut, current_rep, setCheckedList, rdkitSettings }) {
    const [comp, setComp] = React.useState(React.createElement("div", null));
    const ref = React.useRef();
    const { cancellablePromise, cancelPromises } = promise_helpers_1.default();
    React.useEffect(() => {
        cancelPromises(); // cancel all unresolved promises
    }, [selection, current_rep]);
    React.useEffect(() => {
        if (setCheckedList)
            setCheckedList([]);
        loadImage({ id: id, columns: columns, aggregate: aggregate, current_rep: current_rep, selection: selection, rdkitSettings: rdkitSettings }, setComp, handleMouseEnter, handleMouseOut, cancellablePromise, setCheckedList);
    }, [selection]);
    React.useEffect(() => {
        if (aggregate)
            updateImage({ id: id, columns: columns, current_rep: current_rep, selection: selection, imgContainer: ref === null || ref === void 0 ? void 0 : ref.current, rdkitSettings: rdkitSettings }, cancellablePromise);
    }, [current_rep, rdkitSettings.refresh]);
    React.useEffect(() => {
        if (aggregate) {
            //@ts-ignore
            let container = chemRef === null || chemRef === void 0 ? void 0 : chemRef.current;
            let imgContainer = container.getElementsByClassName('chem-grid')[0];
            //@ts-ignore
            let imgList = imgContainer.childNodes;
            if (hoverState && hoverState.data) {
                const idx = selection.findIndex((x) => x && x["__meta__"] && hoverState.data["__meta__"] && x["__meta__"]["view"]["meshIndex"] == hoverState.data["__meta__"]["view"]["meshIndex"]);
                if (idx >= 0 && imgList.length > 0) {
                    for (const i in imgList) {
                        const img_div = imgList[i];
                        removeHighlight(img_div);
                    }
                    addHighlight(imgList[idx]);
                    if (hoverState.updater != UPDATER) {
                        if (container && imgList[idx]) {
                            //@ts-ignore
                            container.scrollTop = imgList[idx].offsetTop - container.offsetTop;
                            // imgList[idx]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) // this seems to be buggy sometimes
                        }
                    }
                }
            }
            else {
                for (const i in imgList) {
                    const img_div = imgList[i];
                    removeHighlight(img_div);
                }
            }
        }
    }, [hoverState]);
    return React.createElement("div", { className: "chemContainer" },
        React.createElement(core_1.Grid, { ref: ref, className: "chem-grid", container: true }, comp));
});
function ValueLabelComponent(props) {
    const { children, open, value } = props;
    return (React.createElement(core_1.Tooltip, { open: open, enterTouchDelay: 0, placement: "top", title: value }, children));
}
const mapStateToProps_settings = (state) => ({
    rdkitSettings: state.rdkitSettings,
});
const mapDispatchToProps_settings = dispatch => ({
    setContourLines: input => dispatch(RDKitSettingsDuck_1.setRDKit_contourLines(input)),
    setScale: input => dispatch(RDKitSettingsDuck_1.setRDKit_scale(input)),
    setSigma: input => dispatch(RDKitSettingsDuck_1.setRDKit_sigma(input)),
    setShowMCS: input => dispatch(RDKitSettingsDuck_1.setRDKit_showMCS(input)),
    setWidth: input => dispatch(RDKitSettingsDuck_1.setRDKit_width(input)),
    setRefresh: input => dispatch(RDKitSettingsDuck_1.setRDKit_refresh(input)),
    setDoAlignment: input => dispatch(RDKitSettingsDuck_1.setRDKit_doAlignment(input)),
});
const connector_settings = react_redux_1.connect(mapStateToProps_settings, mapDispatchToProps_settings);
const SettingsPopover = connector_settings(function ({ open, setOpen, anchorEl, refreshRepList, rdkitSettings, setContourLines, setScale, setSigma, setShowMCS, setWidth, setRefresh, setDoAlignment }) {
    return React.createElement(core_1.Popover, { disablePortal: true, id: "dialog to open", open: open, anchorEl: anchorEl, onClose: () => setOpen(() => false), anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        } },
        React.createElement("div", null,
            React.createElement(core_1.Paper, { style: { padding: 10, minWidth: 300 } },
                React.createElement(core_1.FormGroup, null,
                    React.createElement(core_1.Button, { size: "small", variant: "outlined", "aria-label": "Refresh Representation List", onClick: () => refreshRepList(true) },
                        React.createElement(Refresh_1.default, null),
                        "Refresh Representation List"),
                    React.createElement(core_1.Typography, { variant: "subtitle2", gutterBottom: true }, "RDKit Settings"),
                    React.createElement(core_1.FormControl, null,
                        React.createElement(core_1.InputLabel, { shrink: true, htmlFor: "contourLinesInput" },
                            "Contour Lines ",
                            React.createElement(core_1.Tooltip, { title: "Number of Contour Lines [0; \u221E] \u2208 \u2115" },
                                React.createElement(Info_1.default, { fontSize: "small" }))),
                        React.createElement(core_1.Input, { id: "contourLinesInput", type: "number", value: rdkitSettings.contourLines, onChange: (event) => {
                                let val = parseInt(event.target.value);
                                if (isNaN(val))
                                    setContourLines(event.target.value);
                                else
                                    setContourLines(Math.max(val, 0));
                            } })),
                    React.createElement(core_1.FormControl, null,
                        React.createElement(core_1.InputLabel, { shrink: true, htmlFor: "ScaleInput" },
                            "Scale ",
                            React.createElement(core_1.Tooltip, { title: "Weight Scale [-1; \u221E] \u2208 \u211D" },
                                React.createElement(Info_1.default, { fontSize: "small" }))),
                        React.createElement(core_1.Input, { id: "ScaleInput", type: "number", value: rdkitSettings.scale, onChange: (event) => {
                                let val = parseFloat(event.target.value);
                                if (isNaN(val))
                                    setScale(event.target.value);
                                else
                                    setScale(Math.max(val, -1));
                            } })),
                    React.createElement(core_1.FormControl, null,
                        React.createElement(core_1.InputLabel, { shrink: true, htmlFor: "SigmaInput" },
                            "Sigma ",
                            React.createElement(core_1.Tooltip, { title: "Sigma for Gaussian ]0; \u221E] \u2208 \u211D. Default of 0 signals the algorithm to infer the value." },
                                React.createElement(Info_1.default, { fontSize: "small" }))),
                        React.createElement(core_1.Input, { id: "SigmaInput", type: "number", inputProps: { step: 0.1 }, value: rdkitSettings.sigma, onChange: (event) => {
                                let val = parseFloat(event.target.value);
                                if (isNaN(val))
                                    setSigma(event.target.value);
                                else
                                    setSigma(Math.max(val, 0));
                            } })),
                    React.createElement(core_1.FormControlLabel, { control: React.createElement(core_1.Switch, { color: "primary", checked: rdkitSettings.showMCS, onChange: (_, value) => { setShowMCS(value); } }), label: "Show MCS" }),
                    React.createElement(core_1.FormControlLabel, { control: React.createElement(core_1.Switch, { color: "primary", checked: rdkitSettings.doAlignment, onChange: (_, value) => { setDoAlignment(value); } }), label: "Align Structure" }),
                    React.createElement(core_1.Typography, { style: { paddingTop: 10 }, gutterBottom: true }, "Image Width"),
                    React.createElement(core_1.Slider, { ValueLabelComponent: ValueLabelComponent, "aria-label": "Set Image Width", value: rdkitSettings.width, onChange: (event, new_val) => {
                            setWidth(new_val);
                        }, min: 50, max: 500, step: 10 }),
                    React.createElement(core_1.Button, { style: { marginTop: 3, maxWidth: 150 }, size: "small", variant: "outlined", onClick: () => { setRefresh(rdkitSettings.refresh += 1); } }, "Apply Settings")))));
});
const RepresentationList = props => {
    const options = props.rep_list.map((rep) => {
        let split = rep.split('_');
        const inputVal = split.pop();
        let group = split.join('_');
        group = group.replace('atom.dprop.', '');
        group = group.replace('atom.dprop', '');
        return {
            group: group,
            value: rep,
            inputValue: inputVal
        };
    });
    const filterOptions = lab_1.createFilterOptions({
        stringify: (option) => { return option.value; },
    });
    return React.createElement(lab_1.Autocomplete, { size: "small", className: props.className, filterOptions: filterOptions, onChange: (event, newValue) => {
            if (newValue)
                props.onChange(newValue.value);
        }, disablePortal: props.hoverSettings.windowMode == HoverSettingsDuck_1.WindowMode.Extern, options: options.sort((a, b) => -b.group.localeCompare(a.group)), groupBy: (option) => option.group, getOptionLabel: (option) => option.inputValue, getOptionSelected: (option, value) => { return option.value == value.value; }, style: { maxWidth: 300 }, defaultValue: options[0], renderInput: (params) => React.createElement(core_1.TextField, Object.assign({}, params, { label: "Choose Representation", variant: "outlined" })) });
};
