"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinearColorScale = void 0;
const QualitativeScaleMapping_1 = require("./QualitativeScaleMapping");
const SequentialScaleMapping_1 = require("./SequentialScaleMapping");
var d3v5 = require('d3');
class LinearColorScale {
    constructor(stops, type) {
        this.stops = stops;
        this.type = type;
        this.interpolator = d3v5.scaleLinear()
            .domain(this.stops.map((stop, index) => (1 / (this.stops.length - 1)) * index)).range(this.stops.map(stop => stop.hex));
    }
    createMapping(range) {
        if (this.type == "continuous") {
            return new SequentialScaleMapping_1.SequentialScaleMapping(this, range);
        }
        else {
            return new QualitativeScaleMapping_1.QualitativeScaleMapping(this, range);
        }
    }
}
exports.LinearColorScale = LinearColorScale;
