"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setWebGLView = void 0;
const SET = "ducks/webGLView/SET";
exports.setWebGLView = webGLView => ({
    type: SET,
    webGLView: webGLView
});
const initialState = null;
function webGLView(state = initialState, action) {
    switch (action.type) {
        case SET:
            return action.webGLView;
        default:
            return state;
    }
}
exports.default = webGLView;
